import { AvBillingInfoNotes } from '../../../../avBillingInfos/avBillingInfosTypes';
import { BillingInfoID } from '../../../../billingInfos/billingInfosTypes';
import { InvoicePaymentMethods } from '../../../../invoices/invoicesTypes';
import {
  ItemTypeID,
  ItemTypePrice
} from '../../../../itemTypes/itemTypesTypes';
import {
  ItemDescription,
  ItemItemTypeID,
  ItemPrice,
  ItemQuantity,
  ItemViewPrice,
  ItemViewPriceCurrency
} from '../../../../items/itemsTypes';
import {
  ProjectDescription,
  ProjectFileAttachmentIDs,
  ProjectImplementationDate,
  ProjectName,
  ProjectTeamNanoID
} from '../../../projectsTypes';
import { TaskName, TaskUserID } from '../../../../tasks/tasksTypes';
import { TeamTerms } from '../../../../teams/teamsTypes';
import { TeamsUserUserID } from '../../../../teamsUsers/teamsUsersTypes';
import { Currencies } from '../../../../../types';
import { GeneralLedgerID } from '../../../../generalLedgers/generalLedgersTypes';

export interface CreateProjectInTeamFormDataTaskItemType {
  id: ItemTypeID;
  price: ItemTypePrice;
}

export interface CreateProjectInTeamFormDataTaskItem {
  itemTypeId: ItemItemTypeID;
  description?: ItemDescription;
  quantity: ItemQuantity;
  price: ItemPrice;
  viewPrice: ItemViewPrice;
  viewPriceCurrency: ItemViewPriceCurrency;
}

export type CreateProjectInTeamFormDataTaskItems =
  CreateProjectInTeamFormDataTaskItem[];

export interface CreateProjectInTeamFormDataTask {
  name: TaskName;
  items?: CreateProjectInTeamFormDataTaskItems;
  userIds?: TaskUserID[];
}

export type CreateProjectInTeamFormDataTasks =
  CreateProjectInTeamFormDataTask[];

export interface CreateProjectInTeamFormData {
  billingInfoId?: BillingInfoID;
  createInvoice?: boolean;
  description: ProjectDescription;
  fileAttachmentIds?: ProjectFileAttachmentIDs;
  implementationDate: ProjectImplementationDate;
  name: ProjectName;
  notes?: AvBillingInfoNotes;
  ownerId: TeamsUserUserID;
  paymentMethod: InvoicePaymentMethods;
  tasks: CreateProjectInTeamFormDataTasks;
  teamNanoId: ProjectTeamNanoID;
  terms?: TeamTerms;
  preferredCurrency?: Currencies;
  generalLedgerId?: GeneralLedgerID;
  splitParts?: CreateProjectInTeamsSplitPart[];
}

export const enum CreateProjectInTeamFormFields {
  BILLING_INFO_ID = 'billingInfoId',
  DESCRIPTION = 'description',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  IMPLEMENTATION_DATE = 'implementationDate',
  NAME = 'name',
  NOTES = 'notes',
  OWNER_ID = 'ownerId',
  PAYMENT_METHOD = 'paymentMethod',
  TASKS = 'tasks',
  TEAM_NANO_ID = 'teamNanoId',
  TERMS = 'terms',
  PREFERRED_CURRENCY = 'preferredCurrency',
  GENERAL_LEDGER_ID = 'generalLedgerId',
  SPLIT_PARTS = 'splitParts'
}

export const enum CreateProjectInTeamAuthFormSteps {
  FIRST = 'first',
  SECOND = 'second'
}

export interface CreateProjectInTeamsSplitPart {
  partValue: number | string;
}
