import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { UserID, UserUUID } from '../../../../../main/users/usersTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useSelectedUsers } from '../../../../../main/common/modalButtons/InviteUsersModalButton/hooks/useSelectedUsers';

import { FormModalButton } from '../../../../buttons/FormModalButton';
import { InviteUsersModalBody } from '../../../../../main/common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody';
import { TooltipPlacement } from '../../../../tooltips/tooltipsConstants';

import { words } from '../../../../../locales/keys';

const ADD_USERS_FORM = 'add-users-form';

interface AddUsersModalButtonProps {
  className?: ClassName;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  initialUserUuids?: UserUUID[];
  onAddUsers: (value: UserID[]) => void;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function AddUsersModalButton({
  className,
  disabled,
  i18nText,
  icon,
  iconClassName,
  initialUserUuids,
  onAddUsers,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: AddUsersModalButtonProps) {
  const {
    selectedUsers,
    selectedUserIds,
    handleSelectUser,
    handleDeselectUser,
    handleDeselectAllUsers
  } = useSelectedUsers();

  const handleAddUserIds = useCallback<() => Promise<void>>(
    async () => onAddUsers(selectedUserIds),
    [onAddUsers, selectedUserIds]
  );

  return (
    <FormModalButton
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 hidden sm:flex'
      }
      disabled={disabled}
      form={ADD_USERS_FORM}
      i18nSubmitText={words.submit}
      i18nText={i18nText}
      i18nTitle={words.invite_user}
      icon={icon}
      iconClassName={iconClassName || 'h-9 w-9 p-2 mr-2'}
      onOpen={handleDeselectAllUsers}
      onSubmit={handleAddUserIds}
      submitDisabled={isEmpty(selectedUserIds)}
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <div className="px-4">
          <InviteUsersModalBody
            invitedUserUuids={initialUserUuids}
            selectedUsers={selectedUsers}
            selectedUserIds={selectedUserIds}
            onSelectUser={handleSelectUser}
            onDeselectUser={handleDeselectUser}
          />
        </div>
      </div>
    </FormModalButton>
  );
}

export default AddUsersModalButton;
