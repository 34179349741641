import { useCallback, useMemo, useState } from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { useModal } from '../../../../../../../../helpers/modals/hooks/useModal';
import { useFinTeam } from '../../../../../../hooks/useFinTeam';
import { useFinTeams } from '../../../../../../hooks/useFinTeams';
import { useCurrentUser } from '../../../../../../../../auth/hooks/useAuth';

import {
  FETCH_SELECT_FIELD_TEAMS_QUERY,
  FetchSelectFieldTeamsQueryResponse
} from '../../../../../../queries/fetchSelectFieldTeams.query';
import {
  FETCH_SELECT_FIELD_TEAM_QUERY,
  FetchSelectFieldTeamQueryResponse
} from '../../../../../../queries/fetchSelectFieldTeam.query';

import {
  MultiSelectChangeCallbackType,
  MultiSelectDataType,
  MultiSelectExtendedDataType,
  MultiSelectInputChangeCallbackType
} from '../../../../../../../../helpers/MultiSelect/types';
import { FetchTeamsFilters, TeamNanoID } from '../../../../../../teamsTypes';
import { TeamNanoIdSelectFieldTeamType } from '../../TeamNanoIdSelectFieldControl.types';

import { TeamsPermissions } from '../../../../../../teamsConstants';
import { TeamCache } from '../../../../../../TeamCache';

export interface TeamNanoIdSelectFieldControlOptions {
  afterChange?: (team: TeamNanoIdSelectFieldTeamType) => void;
  initialFilters?: FetchTeamsFilters;
  name: string;
  onChange: (value: TeamNanoID) => void;
  value: TeamNanoID;
  withChangeConfirmation?: boolean;
}

function useFinTeamNanoIdSelectFieldControl({
  afterChange,
  initialFilters = {},
  name,
  onChange,
  value,
  withChangeConfirmation
}: TeamNanoIdSelectFieldControlOptions) {
  const currentUser = useCurrentUser();

  const {
    teams,
    teamsLoading,
    teamsError,
    changeTeamsFilters,
    loadMoreTeams,
    teamsFetchingNextPage
  } = useFinTeams<FetchSelectFieldTeamsQueryResponse>({
    query: FETCH_SELECT_FIELD_TEAMS_QUERY,
    cacheKey: TeamCache.indexSelectCacheKey(name),
    initialFilters
  });

  const {
    team: selectedTeam,
    teamLoading: selectedTeamLoading,
    teamError: selectedTeamError
  } = useFinTeam<FetchSelectFieldTeamQueryResponse>({
    cacheKey: TeamCache.selectedTeamSelectFieldCacheKey(name),
    query: FETCH_SELECT_FIELD_TEAM_QUERY,
    uuid: value,
    options: {
      enabled: !isEmpty(value),
      enabledPlaceholder: !isEmpty(value)
    }
  });

  const data = useMemo<MultiSelectExtendedDataType[]>(() => {
    const withDescription = currentUser.hasPermissions(
      TeamsPermissions.READ_COMPANY_SELECT_OWNER_EMAIL
    );

    return teams.map((team) => ({
      value: team.nanoId,
      image: team.image?.file,
      label: team.name,
      description: withDescription && team.owner?.email
    }));
  }, [currentUser, teams]);

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) =>
      changeTeamsFilters(
        { nameOrUsersEmail: { ilike: input } },
        input ? [] : ['nameOrUsersEmail']
      ),
    [changeTeamsFilters]
  );

  const selectedValue = useMemo<MultiSelectExtendedDataType>(
    () =>
      selectedTeam
        ? {
            value: selectedTeam.nanoId,
            image: selectedTeam.image?.file,
            label: selectedTeam.name
          }
        : null,
    [selectedTeam]
  );

  const [valueToConfirm, setValueToConfirm] =
    useState<TeamNanoIdSelectFieldTeamType>(null);

  const {
    isOpen: isConfirmModalOpen,
    showModal,
    hideModal,
    handleSubmit
  } = useModal({
    onSubmit: async () => {
      onChange(valueToConfirm?.nanoId);
      afterChange?.(valueToConfirm);
    }
  });

  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (updateValue: MultiSelectDataType) => {
      const updatedTeamNanoId = updateValue?.value as TeamNanoID;

      const updatedTeam = find(
        teams,
        (team) => team.nanoId === updatedTeamNanoId
      );

      if (withChangeConfirmation) {
        setValueToConfirm(updatedTeam);
        showModal();

        return;
      }

      onChange(updatedTeamNanoId);
      afterChange?.(updatedTeam);
    },
    [afterChange, onChange, showModal, withChangeConfirmation, teams]
  );

  return {
    data,
    handleChange,
    handleInputChange,
    handleSubmit,
    hideModal,
    isConfirmModalOpen,
    loadMoreTeams,
    selectedTeamError,
    selectedTeamLoading,
    selectedValue,
    teams,
    teamsError,
    teamsFetchingNextPage,
    teamsLoading
  };
}

export default useFinTeamNanoIdSelectFieldControl;
