import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const tinyNanoId = customAlphabet(alphabet, 8);

function generateTinyNanoId(): string {
  let newId: string;

  do {
    newId = tinyNanoId();
  } while (!/[A-Z]/.test(newId));

  return newId;
}

export default generateTinyNanoId;
