import { Control, UseFormRegister } from 'react-hook-form';
import map from 'lodash/map';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormFields,
  CreateProjectInTeamsSplitPart
} from '../../CreateProjectInTeamForm.types';

import { useCreateProjectInTeamFormSplitPartsInput } from './hooks/useCreateProjectInTeamFormSplitPartsInput';

import { Translate } from '../../../../../../../helpers/Translate';
import { InputField } from '../../../../../../../helpers/FormFields/InputField';

import { itemsKeys } from '../../../../../../../locales/keys';

interface CreateProjectInTeamFormSplitPartsInputProps {
  control: Control<CreateProjectInTeamFormData>;
  register: UseFormRegister<CreateProjectInTeamFormData>;
  watchSplitParts: () => CreateProjectInTeamsSplitPart[];
  setSplitPartValue: (partIndex: number, partValue: number) => void;
  setFocusSplitPart: (index: number) => void;
}

function CreateProjectInTeamFormSplitPartsInput({
  control,
  register,
  watchSplitParts,
  setSplitPartValue,
  setFocusSplitPart
}: CreateProjectInTeamFormSplitPartsInputProps) {
  const { fields, handleChange, placeholderIndex, placeholder } =
    useCreateProjectInTeamFormSplitPartsInput({
      control,
      watchSplitParts,
      setSplitPartValue,
      setFocusSplitPart
    });

  return (
    <div className="bg-gray-50 dark:bg-gray-800 rounded-lg text-sm px-4 py-2">
      <div className="flex gap-3">
        <label htmlFor="parts-custom">
          <Translate id={itemsKeys.splitParts} />:
        </label>
        <div className="flex gap-2">
          {map(fields, (field, index) => {
            const registerInput = register(
              `${CreateProjectInTeamFormFields.SPLIT_PARTS}.${index}.partValue`
            );

            return (
              <InputField
                key={field.id}
                inputClassName="text-center number-appearance-none dark:bg-transparent dark:text-gray-200 border-0 border-b border-gray-300 dark:border-gray-700 p-0 w-8 inline-flex focus:ring-0 focus:border-blue-500 text-sm"
                name={registerInput.name}
                onChange={registerInput.onChange}
                ref={registerInput.ref}
                onBlur={async (e) => {
                  await registerInput.onBlur(e);
                  handleChange();
                }}
                placeholder={
                  index === placeholderIndex.current
                    ? String(placeholder)
                    : undefined
                }
                type="number"
                min={0}
                max={100}
                step={1}
              />
            );
          })}
        </div>
        <span className="text-gray-500">%</span>
      </div>
      <p className="text-gray-600 dark:text-gray-400 mt-2">
        Each item will have a set number of regular invoices, priced
        proportionally.
      </p>
    </div>
  );
}

export default CreateProjectInTeamFormSplitPartsInput;
