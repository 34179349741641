import { CreateProjectInTeamAuthFormSteps } from '../../CreateProjectInTeamForm.types';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../../../helpers/buttons/PureSubmitButtonHelper';

import { projectsKeys, words } from '../../../../../../../locales/keys';

interface CreateProjectInTeamAuthFormFooterProps {
  createProjectInTeamLoading: boolean;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  skipHref: string;
  step: CreateProjectInTeamAuthFormSteps;
  withSkip: boolean;
}

function CreateProjectInTeamAuthFormFooter({
  createProjectInTeamLoading,
  handleBack,
  handleNext,
  handleSkip,
  skipHref,
  step,
  withSkip
}: CreateProjectInTeamAuthFormFooterProps) {
  return (
    <div className="sticky bottom-0 bg-white dark:bg-gray-900 z-5 border-t dark:border-gray-800">
      <div className="sm:mx-auto sm:w-full sm:max-w-4xl px-4 sm:px-10">
        <div className="flex justify-between gap-4 items-center p-4">
          <div className="flex gap-2">
            {step === CreateProjectInTeamAuthFormSteps.SECOND ? (
              <PureButtonHelper
                className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                i18nText={words.back}
                onClick={handleBack}
              />
            ) : null}
          </div>

          <div className="flex gap-2">
            {skipHref && withSkip ? (
              <NextPureLinkHelper
                i18nText={words.skip}
                className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 flex-1 justify-center"
                href={skipHref}
              />
            ) : null}

            {step === CreateProjectInTeamAuthFormSteps.FIRST ? (
              <PureButtonHelper
                className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                i18nText={words.next}
                onClick={handleNext}
              />
            ) : null}

            {step === CreateProjectInTeamAuthFormSteps.SECOND ? (
              <>
                <PureButtonHelper
                  disabled={createProjectInTeamLoading}
                  i18nText={words.skipStep}
                  className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 flex-1 justify-center"
                  onClick={handleSkip}
                />
                <PureSubmitButtonHelper
                  disabled={createProjectInTeamLoading}
                  i18nText={projectsKeys.start}
                  className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProjectInTeamAuthFormFooter;
