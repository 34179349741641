import { gql } from 'graphql-request';

import {
  TeamCreatedAt,
  TeamID,
  TeamImageFile,
  TeamName,
  TeamNanoID,
  TeamOwnerID,
  TeamOwnerNanoID,
  TeamPreferredPaymentMethod,
  TeamTerms,
  TeamUUID
} from '../teamsTypes';

export interface FetchSelectFieldTeamQueryResponse {
  id: TeamID;
  nanoId: TeamNanoID;
  uuid: TeamUUID;
  name: TeamName;
  createdAt: TeamCreatedAt;
  image: {
    file: TeamImageFile;
  };
  owner?: {
    nanoId: TeamOwnerNanoID;
  };
  ownerId?: TeamOwnerID;
  preferredPaymentMethod: TeamPreferredPaymentMethod;
  terms: TeamTerms;
}

export const FETCH_SELECT_FIELD_TEAM_QUERY = gql`
  query SelectFieldTeam($uuid: ID!) {
    team(uuid: $uuid) {
      id
      nanoId
      uuid
      name
      createdAt
      image {
        file
      }
      owner {
        nanoId
      }
      ownerId
      preferredPaymentMethod
      terms
    }
  }
`;
