import toNumber from 'lodash/toNumber';

import { useCreateProjectInTeamFormContext } from '../../../../hooks/useCreateProjectInTeamFormContext';
import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useTeamAccountReceivables } from '../../../../../../../../teams/hooks/useTeamAccountReceivables';

import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { MoneyHelper } from '../../../../../../../../../helpers/MoneyHelper';
import { PercentHelper } from '../../../../../../../../../helpers/PercentHelper';
import { Translate } from '../../../../../../../../../helpers/Translate';

import { amountAllocate } from '../../../../../../../../../utils/amountAllocate';

import {
  formsFields,
  projectsKeys,
  words
} from '../../../../../../../../../locales/keys';

const halfPrepaymentPercentage = 50;

function CreateProjectInTeamFormTasksListTotals() {
  const { selectedTeamNanoId, watchProjectTotal, currencyPrefix } =
    useCreateProjectInTeamFormContext();

  const { team, teamLoading, teamError } = useTeamAccountReceivables({
    teamNanoId: selectedTeamNanoId,
    options: {
      enabled: Boolean(selectedTeamNanoId),
      enabledPlaceholder: Boolean(selectedTeamNanoId)
    }
  });

  useShowToastOnErrorChange({ error: teamError });

  const prepayment = 100 - toNumber(team?.accountReceivables || 0);

  const amountDue =
    prepayment === halfPrepaymentPercentage
      ? amountAllocate(watchProjectTotal)[0]
      : watchProjectTotal;

  return (
    <div className="flex flex-col items-end">
      <div className="grid grid-cols-[auto_auto] gap-x-6">
        {/* Project total*/}
        <span className="text-sm text-left">
          <Translate id={projectsKeys.total} />
        </span>

        <span className="text-sm text-right">
          <MoneyHelper value={watchProjectTotal} currency={currencyPrefix} />
        </span>

        {/* Prepayment */}
        <span className="text-sm text-left">
          <Translate id={formsFields.prepayment} />
        </span>

        <span className="text-sm text-right">
          <LoadingSkeleton loaded={!teamLoading} count={1} className="m-0">
            <PercentHelper percent={prepayment} />
          </LoadingSkeleton>
        </span>

        {/* Tax */}
        <span className="text-sm text-left">
          <Translate id={words.tax} />
        </span>

        <span className="text-sm text-right">
          <MoneyHelper value={0} currency={currencyPrefix} />
        </span>

        {/* Amount due */}
        <span className="text-lg font-semibold mt-1 text-left">
          <Translate id={words.amountDue} />
        </span>

        <span className="text-lg font-semibold mt-1 text-right">
          <MoneyHelper value={amountDue} currency={currencyPrefix} />
        </span>

        <div className="col-span-2 space-y-1 pt-3" />
      </div>
    </div>
  );
}

export default CreateProjectInTeamFormTasksListTotals;
