import { useCallback, useRef, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormDataTaskItem,
  CreateProjectInTeamFormDataTaskItemType
} from '../../../../CreateProjectInTeamForm.types';
import { MoneyType } from '../../../../../../../../../types';

import { useCreateProjectInTeamFormContext } from '../../../../hooks/useCreateProjectInTeamFormContext';
import { amountMultiply } from '../../../../../../../../../utils/amountMultiply';

interface CreateProjectInTeamFormItemsTableOptions {
  control: Control<CreateProjectInTeamFormData>;
  taskIndex: number;
  currencyExchangeRate: MoneyType;
}
function useCreateProjectInTeamFormItemsTable({
  control,
  taskIndex,
  currencyExchangeRate
}: CreateProjectInTeamFormItemsTableOptions) {
  const containerRef = useRef<HTMLDivElement>(null);

  const { getTaskItem } = useCreateProjectInTeamFormContext();

  const [isItemTypeIdsSelectOpen, setIsItemTypeIdsSelectOpen] =
    useState<boolean>(false);

  const handleOpenItemTypeIdsSelect = useCallback<() => void>(
    () => setIsItemTypeIdsSelectOpen(true),
    []
  );

  const handleCloseItemTypeIdsSelect = useCallback<() => void>(
    () => setIsItemTypeIdsSelectOpen(false),
    []
  );

  const {
    append,
    fields: itemsFields,
    insert: insertItem,
    move: moveItem,
    prepend,
    remove
  } = useFieldArray({
    name: `tasks.${taskIndex}.items`,
    control
  });

  return {
    appendItem: useCallback<() => void>(() => {
      handleOpenItemTypeIdsSelect();
      append(
        { itemTypeId: null, description: '', quantity: 1, price: 0 },
        { shouldFocus: false }
      );
    }, [append, handleOpenItemTypeIdsSelect]),
    appendItemWithItemTypeId: useCallback<
      (itemType: CreateProjectInTeamFormDataTaskItemType) => void
    >(
      (itemType) =>
        append({
          itemTypeId: itemType.id,
          description: '',
          quantity: 1,
          price: amountMultiply(itemType.price || 0, currencyExchangeRate)
        }),
      [append, currencyExchangeRate]
    ),
    containerRef,
    getItem: useCallback<
      (itemIndex: number) => CreateProjectInTeamFormDataTaskItem
    >(
      (itemIndex) => getTaskItem(taskIndex, itemIndex),
      [getTaskItem, taskIndex]
    ),
    handleCloseItemTypeIdsSelect,
    handleOpenItemTypeIdsSelect,
    insertItem,
    isItemTypeIdsSelectOpen,
    itemsFields,
    moveItem,
    prependItem: useCallback<() => void>(
      () =>
        prepend({ itemTypeId: null, description: '', quantity: 1, price: 0 }),
      [prepend]
    ),
    removeItem: useCallback<(index: number) => void>(
      (index) => remove(index),
      [remove]
    ),
    removeItems: useCallback<() => void>(() => {
      remove();
    }, [remove])
  };
}

export default useCreateProjectInTeamFormItemsTable;
