import { useCallback } from 'react';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import toString from 'lodash/toString';

import { UserID } from '../../../../../main/users/usersTypes';
import { I18nText, IsDisabled } from '../../../../../types';

import { usePaginatedUsers } from '../../../../../main/users/hooks/usePaginatedUsers';

import {
  FETCH_ITEM_TEAM_USERS_QUERY,
  FetchItemTeamUsersQueryResponse
} from '../../../UsersListPopoverField/components/UsersListPopoverFieldList/queries/fetchItemTeamUsers.query';

import { UserCache } from '../../../../../main/users/UserCache';

const initialFetchLimitUsers = 1000;

interface UsersAvatarsFieldControlOptions {
  disabled?: IsDisabled;
  fieldName: string;
  i18nLabel?: I18nText;
  onChange: (value: UserID[]) => void;
  value?: UserID[];
}

function useUsersAvatarsFieldControl({
  fieldName,
  onChange,
  value
}: UsersAvatarsFieldControlOptions) {
  const {
    users,
    usersFetched,
    usersLimit,
    usersIsPlaceholderData,
    usersLoading,
    filterUsers,
    limitUsers
  } = usePaginatedUsers<FetchItemTeamUsersQueryResponse>({
    cacheKey: UserCache.indexSelectCacheKey(fieldName),
    query: FETCH_ITEM_TEAM_USERS_QUERY,
    initialFilters: {
      id: { in: value }
    },
    initialLimit: initialFetchLimitUsers,
    options: {
      enabled: !isEmpty(value),
      enabledPlaceholder: !isEmpty(value),
      withoutPrefetch: true
    }
  });

  const handleAddUsers = useCallback(
    (ids: UserID[]) => {
      const filteredUserIds = compact(concat(value, ids));
      filterUsers({
        id: isEmpty(filteredUserIds) ? undefined : { in: filteredUserIds }
      });
      onChange(filteredUserIds);
    },
    [filterUsers, onChange, value]
  );

  const handleRemoveUser = useCallback(
    (id: UserID) => {
      const filteredUserIds = filter(
        value,
        (v) => toString(v) !== toString(id)
      );
      filterUsers({
        id: isEmpty(filteredUserIds) ? undefined : { in: filteredUserIds }
      });
      onChange(filteredUserIds);
    },
    [filterUsers, onChange, value]
  );

  return {
    users,
    userUuids: map(users, 'uuid'),
    usersFetched,
    usersIsPlaceholderData,
    usersLimit,
    usersLoading,
    filterUsers,
    limitUsers,
    handleRemoveUser,
    handleAddUsers
  };
}

export default useUsersAvatarsFieldControl;
