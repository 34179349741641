import {
  ItemTypeDescription,
  ItemTypeID,
  ItemTypeImageUrl,
  ItemTypeName
} from '../../../itemTypesTypes';

import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../helpers/ImageHelper';

export interface ItemTypeCardType {
  id: ItemTypeID;
  name: ItemTypeName;
  description: ItemTypeDescription;
  imageUrl: ItemTypeImageUrl;
}

interface ItemTypeCardProps {
  itemType: ItemTypeCardType;
}

function ItemTypeCard({ itemType }: ItemTypeCardProps) {
  return (
    <div>
      <ImageHelper
        className="rounded-md"
        src={itemType.imageUrl}
        version={ImageItemImageVersions.FullScreenThumb}
        alt={itemType.name}
      />
      <div className="mt-2">{itemType.name}</div>
      <div className="text-sm text-gray-600 dark:text-gray-400 mt-2">
        <p>{itemType.description}</p>
      </div>
    </div>
  );
}

export default ItemTypeCard;
