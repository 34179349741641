import { Controller } from 'react-hook-form';

import { TeamNanoID } from '../../teamsTypes';
import { TeamNanoIdSelectFieldProps } from './TeamNanoIdSelectField.types';

import { TeamNanoIdSelectFieldControl } from './components/TeamNanoIdSelectFieldControl';

function TeamNanoIdSelectField<T>({
  afterChange,
  control,
  disabled,
  errorMessage,
  i18nConfirmationText,
  i18nLabel,
  i18nPlaceholder,
  initialFilters,
  isClearable,
  labelClassName,
  name,
  withChangeConfirmation
}: TeamNanoIdSelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <TeamNanoIdSelectFieldControl
          afterChange={afterChange}
          classNamePrefix={error || errorMessage ? 'av_error' : 'av'}
          disabled={disabled}
          error={error?.message || errorMessage}
          i18nConfirmationText={i18nConfirmationText}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          initialFilters={initialFilters}
          inputWrapperClassName="relative mt-1"
          isClearable={isClearable}
          labelClassName={labelClassName}
          menuPosition="fixed"
          name={name}
          onChange={onChange}
          value={value as TeamNanoID}
          withChangeConfirmation={withChangeConfirmation}
        />
      )}
    />
  );
}

export default TeamNanoIdSelectField;
