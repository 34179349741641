import { useCallback, useMemo, useState } from 'react';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useCreateProjectInTeamFormContext } from '../../hooks/useCreateProjectInTeamFormContext';

import { InputField } from '../../../../../../../helpers/FormFields/InputField';

import { ProjectsPermissions } from '../../../../../projectsConstants';

import { formsFields, words } from '../../../../../../../locales/keys';

function CreateProjectInTeamFormImplementationDateField() {
  const currentUser = useCurrentUser();
  const [inputFocused, setInputFocused] = useState(false);

  const {
    selectedImplementationDate,
    createProjectInTeamLoading,
    registerImplementationDate,
    validationErrors
  } = useCreateProjectInTeamFormContext();

  const defaultType = useMemo(
    () =>
      currentUser.hasPermissions(
        ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_TIME
      )
        ? 'datetime-local'
        : 'date',
    [currentUser]
  );

  const toggleInputFocused = useCallback<() => void>(() => {
    setInputFocused((prevValue) => !prevValue);
  }, []);

  return (
    <InputField
      disabled={createProjectInTeamLoading}
      error={validationErrors.implementationDateValidationError}
      i18nLabel={formsFields.implementationDate}
      inputWrapperClassName="relative mt-1"
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      name={registerImplementationDate.name}
      onChange={registerImplementationDate.onChange}
      ref={registerImplementationDate.ref}
      showErrorIcon={false}
      i18nPlaceholder={words.undefined}
      onFocus={toggleInputFocused}
      onBlur={toggleInputFocused}
      type={!selectedImplementationDate && !inputFocused ? 'text' : defaultType}
    />
  );
}

export default CreateProjectInTeamFormImplementationDateField;
