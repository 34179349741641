import { TeamNanoID } from '../../teamsTypes';

import { useFinTeam } from '../useFinTeam';

import {
  FETCH_TEAM_ACCOUNT_RECEIVABLES_QUERY,
  FetchTeamAccountReceivablesQueryResponse
} from '../../queries/fetchTeamAccountReceivables.query';

import { TeamCache } from '../../TeamCache';

interface TeamAccountReceivablesOptions {
  teamNanoId: TeamNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

function useFinTeamAccountReceivables({
  teamNanoId,
  options
}: TeamAccountReceivablesOptions) {
  return useFinTeam<FetchTeamAccountReceivablesQueryResponse>({
    cacheKey: TeamCache.showCompanyAccountReceivablesCacheKey(),
    query: FETCH_TEAM_ACCOUNT_RECEIVABLES_QUERY,
    uuid: teamNanoId,
    options
  });
}

export default useFinTeamAccountReceivables;
