import { Controller } from 'react-hook-form';

import { TeamsUserUserID } from '../../teamsUsersTypes';
import { TeamsUserIdSelectFieldProps } from './TeamsUserIdSelectField.types';

import { TeamsUserIdSelectFieldControl } from './components/TeamsUserIdSelectFieldControl';

function TeamsUserIdSelectField<T>({
  afterChange,
  cacheKey,
  control,
  disabled,
  errorMessage,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName = 'relative mt-1',
  isClearable,
  labelClassName,
  name,
  teamNanoId,
  menuPosition = 'fixed'
}: TeamsUserIdSelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <TeamsUserIdSelectFieldControl
          afterChange={afterChange}
          cacheKey={cacheKey}
          classNamePrefix={error || errorMessage ? 'av_error' : 'av'}
          disabled={disabled}
          error={error?.message || errorMessage}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          inputWrapperClassName={inputWrapperClassName}
          isClearable={isClearable}
          labelClassName={labelClassName}
          menuPosition={menuPosition}
          name={name}
          onChange={onChange}
          teamNanoId={teamNanoId}
          value={value as TeamsUserUserID}
        />
      )}
    />
  );
}

export default TeamsUserIdSelectField;
