import {
  FetchTeamsFilters,
  FetchTeamsSort,
  FetchTeamsPage,
  FetchTeamsLimit
} from '../../teamsTypes';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_TEAMS_FILTERS,
  INITIAL_TEAMS_LIMIT,
  INITIAL_TEAMS_SORT
} from '../../teamsConstants';

interface TeamsOptions {
  cacheKey: string;
  initialFilters?: FetchTeamsFilters;
  initialLimit?: FetchTeamsLimit;
  initialPage?: FetchTeamsPage;
  initialSort?: FetchTeamsSort;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
  query: string;
}

const scope = 'teams';

function useFinTeams<ItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TEAMS_FILTERS,
  initialSort = INITIAL_TEAMS_SORT,
  initialLimit = INITIAL_TEAMS_LIMIT,
  options = {}
}: TeamsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    limitItems
  } = useFinInfiniteIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    teamsData: data,
    teams: items,
    teamsError: itemsError,
    teamsTotalCount: itemsTotalCount,
    teamsFetched: isFetched,
    teamsLoading: isLoading,
    teamsFetchingNextPage: isFetchingNextPage,
    teamsIsPlaceholderData: isPlaceholderData,
    teamsFilters: currentFilters,
    teamsSort: currentSort,
    teamsPage: currentPage,
    teamsLimit: currentLimit,
    teamsHasNextPage: hasNextPage,
    updateTeamCache: updateItemCache,
    loadMoreTeams: loadMoreItems,
    filterTeams: filterItems,
    changeTeamsFilters: changeItemsFilters,
    clearTeamsFilters: clearItemsFilters,
    sortTeams: sortItems,
    limitTeams: limitItems
  };
}

export default useFinTeams;
