import { memo, useMemo } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { useCreateProjectInTeamFormContext } from '../../hooks/useCreateProjectInTeamFormContext';
import { useFinPaginatedItemTypes } from '../../../../../../itemTypes/hooks/useFinPaginatedItemTypes';

import { ItemTypeCardType } from '../../../../../../itemTypes/components/content/ItemTypeCard/ItemTypeCard';
import {
  FETCH_ITEM_TYPES_QUERY,
  FetchItemTypesQueryResponse
} from '../../../../../../itemTypes/queries/fetchItemTypes.query';

import { ItemTypeCard } from '../../../../../../itemTypes/components/content/ItemTypeCard';

import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../helpers/Translate';

import { ItemTypeCache } from '../../../../../../itemTypes/ItemTypeCache';

import { stringsKeys } from '../../../../../../../locales/keys';

export type ItemTypeCardTypes = ItemTypeCardType[];

function CreateProjectInTeamFormItemTypeCardsGrid() {
  const { watchTaskItemIds, selectedTeamNanoId } =
    useCreateProjectInTeamFormContext();

  const { itemTypes, itemTypesFetched } =
    useFinPaginatedItemTypes<FetchItemTypesQueryResponse>({
      query: FETCH_ITEM_TYPES_QUERY,
      cacheKey: ItemTypeCache.companyItemTypesCacheKey(selectedTeamNanoId),
      initialLimit: 1000,
      initialFilters: {
        companyNanoId: { eq: selectedTeamNanoId }
      }
    });

  const selectedItemTypes = useMemo<ItemTypeCardTypes>(
    () =>
      filter(
        itemTypes,
        (itemType) =>
          includes(watchTaskItemIds(0), itemType.id) && !!itemType.imageUrl
      )?.map((itemType) => ({
        id: itemType.id,
        name: itemType.name,
        description: itemType.description,
        imageUrl: itemType.imageUrl
      })),
    [itemTypes, watchTaskItemIds]
  );

  if (itemTypesFetched && isEmpty(selectedItemTypes)) {
    return null;
  }

  return (
    <LoadingSkeleton loaded={itemTypesFetched}>
      <div className="px-4">
        <h2 className="text-lg font-extrabold text-gray-900 dark:text-gray-200 mb-6 mt-8">
          <Translate id={stringsKeys.hereIsTheExampleOfWhatYoullGet} />
        </h2>

        <div className="grid grid-cols-3 gap-x-4 gap-y-6">
          {selectedItemTypes.map((itemType) => (
            <ItemTypeCard key={itemType.id} itemType={itemType} />
          ))}
        </div>
      </div>
    </LoadingSkeleton>
  );
}

export default memo(CreateProjectInTeamFormItemTypeCardsGrid);
