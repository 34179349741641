import { useQueryClient, QueryKey } from 'react-query';

import {
  FetchFinGeneralLedgersFilters,
  FetchGeneralLedgersLimit,
  FetchGeneralLedgersPage,
  FetchGeneralLedgersSort
} from '../../generalLedgersTypes';

import {
  INITIAL_GENERAL_LEDGERS_FILTERS,
  INITIAL_GENERAL_LEDGERS_SORT,
  INITIAL_GENERAL_LEDGERS_PAGE,
  INITIAL_GENERAL_LEDGERS_LIMIT
} from '../../generalLedgersConstants';

interface GeneralLedgersResponse<ItemType> {
  generalLedgers: { nodes: ItemType | null };
}

interface CachedGeneralLedgersOptions {
  cacheKey: QueryKey;
  filters?: FetchFinGeneralLedgersFilters;
  sort?: FetchGeneralLedgersSort;
  page?: FetchGeneralLedgersPage;
  limit?: FetchGeneralLedgersLimit;
}

function useCachedGeneralLedgers<ItemType>({
  cacheKey,
  filters = INITIAL_GENERAL_LEDGERS_FILTERS,
  sort = INITIAL_GENERAL_LEDGERS_SORT,
  page = INITIAL_GENERAL_LEDGERS_PAGE,
  limit = INITIAL_GENERAL_LEDGERS_LIMIT
}: CachedGeneralLedgersOptions) {
  const queryClient = useQueryClient();

  const queryData = queryClient.getQueryData<GeneralLedgersResponse<ItemType>>([
    cacheKey,
    {
      filters,
      sort,
      page,
      limit
    }
  ]);

  const generalLedgers = queryData?.generalLedgers?.nodes;

  return {
    generalLedgers
  };
}

export default useCachedGeneralLedgers;
