import { useCallback, useState } from 'react';

import { useCreateProjectInTeamFormContext } from '../../../../../../hooks/useCreateProjectInTeamFormContext';
import { useTranslate } from '../../../../../../../../../../../common/hooks/useTranslate';

import { formsErrors } from '../../../../../../../../../../../locales/keys';

interface CreateProjectInTeamFormTasksListItemOptions {
  index: number;
  removeTask: (index: number) => void;
}

function useCreateProjectInTeamFormTasksListItem({
  index,
  removeTask
}: CreateProjectInTeamFormTasksListItemOptions) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  const handleRemoveTask = useCallback<() => Promise<void>>(
    async () => removeTask(index),
    [removeTask, index]
  );

  const { t } = useTranslate();

  const {
    control,
    validationErrors: { taskNameValidationError },
    register,
    watchTaskItemsCount
  } = useCreateProjectInTeamFormContext();

  const itemsCount = watchTaskItemsCount(index);

  const registerTaskNameField = register(`tasks.${index}.name`, {
    required: formsErrors.taskName.required
  });

  return {
    control,
    isOpen,
    toggleIsOpen,
    handleRemoveTask,
    t,
    itemsCount,
    registerTaskNameField,
    taskNameErrorMessage: taskNameValidationError(index)
  };
}

export default useCreateProjectInTeamFormTasksListItem;
