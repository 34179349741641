import { ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';

import { CreateProjectInTeamFormItemsTableHandle } from '../CreateProjectInTeamFormItemsTable/CreateProjectInTeamFormItemsTable';
import { IsDisabled, MoneyType } from '../../../../../../../types';

import { useCreateProjectInTeamFormTasksList } from './hooks/useCreateProjectInTeamFormTasksList';

import { CreateProjectInTeamFormTasksListItem } from './components/CreateProjectInTeamFormTasksListItem';
import { CreateProjectInTeamFormTasksListTotals } from './components/CreateProjectInTeamFormTasksListTotals';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { tasksKeys, words } from '../../../../../../../locales/keys';
import { ProjectsPermissions } from '../../../../../projectsConstants';

export type CreateProjectInTeamFormTasksListHandle = {
  removeAllTasksItems: () => void;
};

interface CreateProjectInTeamFormTasksListProps {
  disabled?: IsDisabled;
  currencyExchangeRate: MoneyType;
  currencyPrefix: string;
}

function CreateProjectInTeamFormTasksList(
  {
    disabled,
    currencyExchangeRate,
    currencyPrefix
  }: CreateProjectInTeamFormTasksListProps,
  ref: ForwardedRef<CreateProjectInTeamFormTasksListHandle>
) {
  const {
    appendTask,
    getTasksRefsMap,
    handleDragItemEnd,
    removeAllTasksItems,
    removeTask,
    tasksFields
  } = useCreateProjectInTeamFormTasksList();

  useImperativeHandle(ref, () => ({ removeAllTasksItems }));

  return (
    <>
      <div className="flex justify-between">
        <div>
          <div className="text-sm font-medium">
            <Translate id={words.scopeOfWork} />
          </div>
          {/*<div className="text-xs text-gray-500">*/}
          {/*  <Translate*/}
          {/*    id={*/}
          {/*      projectsKeys.youCanCreateYourOwnInvoiceAndAddHereAllThePartsOfYourProject*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>

      <DragDropContext onDragEnd={handleDragItemEnd}>
        <div className="space-y-8">
          {tasksFields.map((taskField, index) => (
            <CreateProjectInTeamFormTasksListItem
              disabled={disabled}
              index={index}
              key={taskField.id}
              removeTask={removeTask}
              ref={(node: CreateProjectInTeamFormItemsTableHandle) => {
                const map = getTasksRefsMap();

                if (node) {
                  map.set(taskField.id, node);
                  return;
                }

                map.delete(taskField.id);
              }}
              taskFieldId={taskField.id}
              currencyExchangeRate={currencyExchangeRate}
              currencyPrefix={currencyPrefix}
            />
          ))}
        </div>
      </DragDropContext>

      <CheckPermissions
        action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_ADD_TASK_BUTTON}
      >
        <div className="relative flex justify-center">
          <PureButtonHelper
            className="py-1.5 pl-3 pr-3 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            disabled={disabled}
            i18nText={tasksKeys.add}
            onClick={appendTask}
          />
          <hr className="absolute -z-1 w-full top-1/2 border-dashed dark:border-gray-700" />
        </div>
      </CheckPermissions>

      <CreateProjectInTeamFormTasksListTotals />
    </>
  );
}

export default forwardRef<
  CreateProjectInTeamFormTasksListHandle,
  CreateProjectInTeamFormTasksListProps
>(CreateProjectInTeamFormTasksList);
