import { Control } from 'react-hook-form';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormFields
} from '../../../CreateProjectInTeamForm.types';
import {
  ClassName,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../../../../types';
import { CreateProjectInTeamFormValidationErrorType } from '../../../hooks/useCreateProjectInTeamFormContext';
import AuthenticatedUser from '../../../../../../../../auth/hooks/useAuth/AuthenticatedUser';

import { Translate } from '../../../../../../../../helpers/Translate';
import { InputField } from '../../../../../../../../helpers/FormFields/InputField';
import { TextAreaField } from '../../../../../../../../helpers/FormFields/TextAreaField';
import { DropzoneField } from '../../../../../../../../helpers/FormFields/DropzoneField';

import {
  attachmentsKeys,
  formsFields,
  projectsKeys,
  stringsKeys
} from '../../../../../../../../locales/keys';

import { ProjectsPermissions } from '../../../../../../projectsConstants';

interface CreateProjectInTeamAuthFormFirstStepContentProps {
  className: ClassName;
  control: Control<CreateProjectInTeamFormData>;
  createProjectInTeamLoading: IsLoading;
  currentUser: AuthenticatedUser;
  formNanoId: string;
  registerDescription: RegisterFormFieldType<HTMLTextAreaElement>;
  registerImplementationDate: RegisterFormFieldType<HTMLInputElement>;
  registerName: RegisterFormFieldType<HTMLInputElement>;
  validationErrors: CreateProjectInTeamFormValidationErrorType;
}

function CreateProjectInTeamAuthFormFirstStepContent({
  className,
  control,
  createProjectInTeamLoading,
  currentUser,
  formNanoId,
  registerDescription,
  registerImplementationDate,
  registerName,
  validationErrors
}: CreateProjectInTeamAuthFormFirstStepContentProps) {
  return (
    <div className={className}>
      <div className="px-4">
        <h2 className="text-2xl font-extrabold text-gray-900 dark:text-gray-200 mb-6">
          <Translate id={stringsKeys.letsCreateYourFirstProject} />
        </h2>
        <div className="text-md text-gray-700 dark:text-gray-300 mb-6">
          <p>
            <Translate id={stringsKeys.startByOutliningYourProjectVisionBe} />
          </p>
          <p>
            <Translate id={stringsKeys.ourExpertTeamWillWorkCloselyWith} />
          </p>
        </div>
      </div>

      <div className="p-4">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1 flex flex-col gap-3">
              <InputField
                disabled={createProjectInTeamLoading}
                error={validationErrors.nameValidationError}
                i18nLabel={projectsKeys.name}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerName.name}
                onChange={registerName.onChange}
                ref={registerName.ref}
                type="text"
              />

              <InputField
                disabled={createProjectInTeamLoading}
                error={validationErrors.implementationDateValidationError}
                i18nLabel={formsFields.implementationDate}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerImplementationDate.name}
                onChange={registerImplementationDate.onChange}
                ref={registerImplementationDate.ref}
                showErrorIcon={false}
                type={
                  currentUser.hasPermissions(
                    ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_TIME
                  )
                    ? 'datetime-local'
                    : 'date'
                }
              />
            </div>

            <div className="flex-1">
              <TextAreaField
                disabled={createProjectInTeamLoading}
                i18nLabel={formsFields.description}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                textAreaWrapperClassName="mt-1"
                name={registerDescription.name}
                onChange={registerDescription.onChange}
                ref={registerDescription.ref}
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="space-y-4">
          <div>
            <div className="text-sm font-medium">
              <Translate id={attachmentsKeys.plural} />
            </div>
            <div className="text-xs text-gray-500">
              <Translate
                id={
                  projectsKeys.youCanAddFilesRelatedToTheProjectSuchAsDrawingsTexturesReferencesModelsEtc
                }
              />
            </div>
          </div>

          <div>
            <DropzoneField
              control={control}
              disabled={createProjectInTeamLoading}
              key={formNanoId}
              name={CreateProjectInTeamFormFields.FILE_ATTACHMENT_IDS}
              type="fileAttachments"
              withoutTabs
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProjectInTeamAuthFormFirstStepContent;
