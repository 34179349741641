import { ProjectID, ProjectName } from '../../../../projects/projectsTypes';
import { TaskName, TaskNanoID } from '../../../tasksTypes';
import { CreateTaskItemsInputItems } from '../../../hooks/useCreateTaskItems/useCreateTaskItems';
import { Currencies, ErrorMessage } from '../../../../../types';
import {
  ItemTypeID,
  ItemTypePrice,
  ItemTypeViewPrice,
  ItemTypeViewPriceCurrency
} from '../../../../itemTypes/itemTypesTypes';
import { GeneralLedgerID } from '../../../../generalLedgers/generalLedgersTypes';

export interface CreateTaskItemsFormProject {
  value: ProjectID;
  label: ProjectName;
}

export interface CreateTaskItemsFormTask {
  value: TaskNanoID;
  label: TaskName;
}
export interface CreateTaskItemsFormDefaultProject {
  id: ProjectID;
  name: ProjectName;
}

export interface CreateTaskItemsFormDefaultTask {
  nanoId: TaskNanoID;
  name: TaskName;
}

export interface CreateTaskItemsSplitPart {
  partValue: number | string;
}

export type CreateTaskItemsFormData = {
  generalLedgerId: GeneralLedgerID;
  project: CreateTaskItemsFormProject;
  task: CreateTaskItemsFormTask;
  items: CreateTaskItemsInputItems;
  currency?: Currencies;
  splitParts: CreateTaskItemsSplitPart[];
};

export interface CreateTaskItemsFormDataItemType {
  id: ItemTypeID;
  price: ItemTypePrice;
  viewPrice?: ItemTypeViewPrice;
  viewPriceCurrency?: ItemTypeViewPriceCurrency;
}

export const enum CreateTaskItemsFormFields {
  GENERAL_LEDGER_ID = 'generalLedgerId',
  PROJECT = 'project',
  TASK = 'task',
  ITEMS = 'items',
  CURRENCY = 'currency',
  SPLIT_PARTS = 'splitParts'
}

export interface CreateTaskItemsFormCurrentProject {
  id: ProjectID;
  name: ProjectName;
}

export interface CreateTaskItemsFormCurrentTask {
  nanoId: TaskNanoID;
  name: TaskName;
}

export type ValidationErrorsType = {
  projectValidationErrorMessage?: ErrorMessage;
  taskValidationErrorMessage?: ErrorMessage;
  itemsValidationErrorMessage?: ErrorMessage;
};
