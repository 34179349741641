import {
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  NanoID,
  NanoIdFilterType,
  TextFilterType,
  UpdatedAt,
  UpdateItemCacheKeys,
  UpdateItemIsLoading,
  UUID
} from '../../types';
import { CompanyID } from '../companies/companiesTypes';
import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';

export type ItemTagID = ID;
export type ItemTagUUID = UUID;
export type ItemTagNanoID = NanoID;
export type ItemTagCreatedAt = CreatedAt;
export type ItemTagUpdatedAt = UpdatedAt;
export type ItemTagName = string;
export type ItemTagCompanyId = CompanyID;
export type ItemTagPosition = number;

export const enum FetchItemTagsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export interface FetchItemTagsFilters {
  companyNanoId?: NanoIdFilterType;
  name?: TextFilterType;
  id?: IdFilterType;
  nanoId?: NanoIdFilterType;
}

export type FetchItemTagsGqlQuery = FetchItemsGqlQuery;

export type FetchItemTagsCacheKey = FetchItemsCacheKey;
export type FetchItemTagsCacheKeys = FetchItemTagsCacheKey[];

export type FetchItemTagsSort = FetchItemsSort;
export type FetchItemTagsSortItemTags = FetchItemsSortItems;
export type FetchItemTagsPage = FetchItemsPage;
export type FetchItemTagsLimit = FetchItemsLimit;
export type FetchItemTagsErrorMessage = FetchItemsErrorMessage;
export type FetchItemTagsFetched = FetchItemsFetched;
export type FetchItemTagsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchItemTagsPaginateItemTags = FetchItemsPaginateItems;
export type FetchItemTagsPrefetchItemTags = FetchItemsPrefetchItems;
export type FetchItemTagsTotalCount = FetchItemsTotalCount;

export type FetchItemTagsShowItemTag = FetchItemsShowItem;
export type FetchItemTagsEditItemTag = FetchItemsEditItem;
export type FetchItemTagsChecked = Checked;
export type FetchItemTagsCheckedHash = CheckedHashItem;
export type FetchItemTagsOnSetCheckedIds = OnSetCheckedIds;
export type FetchItemTagsCheckedAll = CheckedAll;
export type FetchItemTagsOnCheckAll = OnCheckAll;

export const enum FetchItemTagsSortableFields {
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export const enum ItemTagFields {
  NAME = 'name'
}

export type CreateItemTagIsLoading = CreateItemIsLoading;
export type CreateItemTagCacheKeys = CreateItemCacheKeys;

export type UpdateItemTagIsLoading = UpdateItemIsLoading;
export type UpdateItemTagCacheKeys = UpdateItemCacheKeys;

export type DeleteItemTagCacheKeys = DeleteItemCacheKeys;
