import { useCallback } from 'react';
import {
  TeamNanoIdSelectFieldControlProps,
  TeamNanoIdSelectFieldControlRequiredProps
} from './TeamNanoIdSelectFieldControl.types';
import {
  FormatOptionLabelType,
  MultiSelectExtendedDataType
} from '../../../../../../helpers/MultiSelect/types';

import { useTeamNanoIdSelectFieldControl } from './hoooks/useTeamNanoIdSelectFieldControl';

import { MultiSelect } from '../../../../../../helpers/MultiSelect';
import { SimpleModal } from '../../../../../../helpers/modals/SimpleModal';
import { Translate } from '../../../../../../helpers/Translate';

import { words } from '../../../../../../locales/keys';

function TeamNanoIdSelectFieldControl({
  afterChange,
  classNamePrefix,
  closeMenuOnSelect = true,
  disabled = false,
  emptyValue = null,
  error,
  i18nConfirmationText,
  i18nLabel = null,
  i18nPlaceholder,
  initialFilters = {},
  inputWrapperClassName = null,
  isClearable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  name,
  onChange,
  placeholder = null,
  value,
  withChangeConfirmation = false
}: TeamNanoIdSelectFieldControlProps &
  TeamNanoIdSelectFieldControlRequiredProps) {
  const {
    data,
    handleChange,
    handleInputChange,
    handleSubmit,
    hideModal,
    isConfirmModalOpen,
    loadMoreTeams,
    selectedTeamError,
    selectedTeamLoading,
    selectedValue,
    teamsError,
    teamsFetchingNextPage,
    teamsLoading
  } = useTeamNanoIdSelectFieldControl({
    afterChange,
    initialFilters,
    name,
    onChange,
    value,
    withChangeConfirmation
  });

  const formatOptionLabel = useCallback<FormatOptionLabelType>(
    (option: MultiSelectExtendedDataType, { context }) => {
      if (context === 'menu') {
        return (
          <div className="flex-1 flex relative items-center space-x-3 truncate">
            <div className="flex-1 min-w-0">
              <p className="font-medium text-gray-900 dark:text-gray-200 truncate">
                {option.label}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 truncate space-x-1">
                {option.description}
              </p>
            </div>
          </div>
        );
      }
      return option.label;
    },
    []
  );

  return (
    <>
      <MultiSelect
        classNamePrefix={classNamePrefix}
        closeMenuOnSelect={closeMenuOnSelect}
        data={data}
        formatOptionLabel={formatOptionLabel}
        disabled={disabled}
        emptyValue={emptyValue}
        error={error || teamsError || selectedTeamError}
        i18nLabel={i18nLabel}
        i18nPlaceholder={i18nPlaceholder}
        inputWrapperClassName={inputWrapperClassName}
        isClearable={isClearable}
        isLoading={teamsFetchingNextPage}
        isSearchable
        labelClassName={labelClassName}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        multi={false}
        name={name}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={loadMoreTeams}
        optionsLoading={teamsLoading || selectedTeamLoading}
        placeholder={placeholder}
        value={selectedValue}
      />

      <SimpleModal
        handleSubmit={handleSubmit}
        hideModal={hideModal}
        i18nSubmitText={words.ok}
        isOpen={isConfirmModalOpen}
        submitButtonColor="red"
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            <Translate id={i18nConfirmationText} />
          </p>
        </div>
      </SimpleModal>
    </>
  );
}

export default TeamNanoIdSelectFieldControl;
