import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { UserID } from '../../../../../usersTypes';
import { UsersSimpleModalButtonUserItem } from '../../UsersSimpleModalButton.types';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../helpers/Icon';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { UserAvatarLink } from '../../../../../../common/helpers/UserAvatarLink';
import { UserLink } from '../../../../../../common/helpers/UserLink';

import { UsersPermissions } from '../../../../../usersConstants';

interface UsersSimpleModalButtonUserProps {
  user: UsersSimpleModalButtonUserItem;
  onRemoveUser?: (id: UserID) => void;
  withFinanceRole?: boolean;
}

function UsersSimpleModalButtonUser({
  user,
  onRemoveUser,
  withFinanceRole = false
}: UsersSimpleModalButtonUserProps) {
  const handleRemoveUser = useCallback<() => void>(
    () => onRemoveUser?.(user.id),
    [onRemoveUser, user.id]
  );

  return (
    <li className="group p-2 -mx-2 rounded-lg bg-gray-500 bg-opacity-0 hover:bg-opacity-10">
      <div className="flex items-center space-x-2">
        <div className="flex-1 flex relative items-center space-x-3 truncate rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100">
          <div className="flex-shrink-0">
            <UserAvatarLink avatarType="main" user={user} />
          </div>

          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
              <UserLink user={user} className="focus:outline-none" withoutTitle>
                {' '}
                {user.fullName}{' '}
                <span className="absolute inset-0" aria-hidden />
              </UserLink>
            </p>

            <div className="flex text-sm truncate text-gray-500">
              {user.blocked ? (
                <CheckPermissions
                  action={UsersPermissions.READ_USER_BLOCKED_FIELD}
                >
                  <span className="flex items-center justify-center z-10 cursor-pointer">
                    <Icon
                      className="h-4 w-4 inline text-red-500 mr-1"
                      icon={IconsEnum.BLOCK}
                    />
                  </span>
                </CheckPermissions>
              ) : null}

              {withFinanceRole ? user.financeRoleName : undefined}
            </div>
          </div>
        </div>

        {onRemoveUser && (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-offset-0 flex-shrink self-center text-gray-500 hover:bg-gray-300"
            icon={IconsEnum.TRASH_SOLID}
            iconClassName="h-4 w-4"
            onClick={handleRemoveUser}
          />
        )}
      </div>
    </li>
  );
}

export default UsersSimpleModalButtonUser;
