import { gql } from 'graphql-request';

import {
  UserCreatedAt,
  UserFullName,
  UserID,
  UserImageFile,
  UserUUID
} from '../usersTypes';

export interface FetchSelectFieldUserQueryResponse {
  id: UserID;
  uuid: UserUUID;
  createdAt: UserCreatedAt;
  fullName: UserFullName;
  image: {
    file: UserImageFile;
  };
}

export const FETCH_SELECT_FIELD_USER_QUERY = gql`
  query SelectFieldUser($uuid: ID!) {
    user(uuid: $uuid) {
      id
      uuid
      createdAt
      fullName
      image {
        file
      }
    }
  }
`;
