import { Draggable } from '@hello-pangea/dnd';

import { useCreateProjectInTeamFormItemsTableRow } from './hooks/useCreateProjectInTeamFormItemsTableRow';
import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormDataTaskItem
} from '../../../../CreateProjectInTeamForm.types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ID, MoneyType } from '../../../../../../../../../types';
import { ItemTypeID } from '../../../../../../../../itemTypes/itemTypesTypes';
import { ItemTypeCache } from '../../../../../../../../itemTypes/ItemTypeCache';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { ConfirmModalButton } from '../../../../../../../../../helpers/buttons/ConfirmModalButton';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { InputField } from '../../../../../../../../../helpers/FormFields/InputField';
import { ItemTypeIdsSelectField } from '../../../../../../../../itemTypes/helpers/ItemTypeIdsSelectField';
import { MoneyHelper } from '../../../../../../../../../helpers/MoneyHelper';

import {
  itemsKeys,
  itemTypesKeys,
  words
} from '../../../../../../../../../locales/keys';
import { ProjectsPermissions } from '../../../../../../../projectsConstants';

interface CreateProjectInTeamFormItemsTableRowProps {
  defaultItemTypeId: ItemTypeID;
  getItem: (itemIndex: number) => CreateProjectInTeamFormDataTaskItem;
  index: number;
  isItemTypeIdsSelectOpen?: boolean;
  itemFieldId: ID;
  onCloseItemTypeIdsSelect?: () => void;
  removeItem: (index: number) => void;
  taskIndex: number;
  currencyExchangeRate: MoneyType;
  currencyPrefix: string;
}
function CreateProjectInTeamFormItemsTableRow({
  defaultItemTypeId,
  getItem,
  index,
  isItemTypeIdsSelectOpen,
  itemFieldId,
  onCloseItemTypeIdsSelect,
  removeItem,
  taskIndex,
  currencyExchangeRate,
  currencyPrefix
}: CreateProjectInTeamFormItemsTableRowProps) {
  const {
    control,
    handleOnChange,
    handleRemoveItem,
    itemTypesFilters,
    registerItemDescriptionField,
    registerItemPriceField,
    registerItemQuantityField,
    selectedItemTypeId,
    selectedTeamNanoId,
    taskItemPriceValidationErrorMessage,
    taskItemQtyValidationErrorMessage,
    taskItemTypeIdValidationErrorMessage,
    watchTaskItemSubtotal,
    watchCurrency
  } = useCreateProjectInTeamFormItemsTableRow({
    getItem,
    index,
    removeItem,
    taskIndex,
    currencyExchangeRate
  });

  const currentUser = useCurrentUser();

  return (
    <Draggable draggableId={`${taskIndex}-${itemFieldId}`} index={index}>
      {(provided) => (
        <tr ref={provided.innerRef} {...provided.draggableProps}>
          <td className="left-0 sticky pl-4 sm:pl-6 pr-2 w-px z-5 focus-within:z-5 bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90">
            <div
              className="text-gray-300 dark:text-gray-700 hover:text-gray-600 dark:hover:text-gray-400 cursor-grab absolute inset-0 flex items-center justify-end px-2 sm:px-0"
              {...provided.dragHandleProps}
            >
              <Icon icon={IconsEnum.MENU_ALT_SOLID} />
            </div>
          </td>

          {/*Item type id*/}
          <td className="py-1 px-2">
            <ItemTypeIdsSelectField<CreateProjectInTeamFormData>
              defaultItemTypeId={defaultItemTypeId}
              autoFocus
              cacheKey={ItemTypeCache.companySelectCacheKey(selectedTeamNanoId)}
              control={control}
              disabled={
                !selectedTeamNanoId ||
                !currentUser.hasPermissions(
                  ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_ITEM_TYPE_ID
                )
              }
              disabledItemTypesLoading={!selectedTeamNanoId}
              errorMessage={taskItemTypeIdValidationErrorMessage}
              i18nPlaceholder={itemTypesKeys.select}
              itemTypesFilters={itemTypesFilters}
              inputWrapperClassName="w-full inline-block text-sm font-normal"
              name={`tasks.${taskIndex}.items.${index}.itemTypeId`}
              onChange={handleOnChange}
              onMenuClose={onCloseItemTypeIdsSelect}
              openMenuOnFocus={isItemTypeIdsSelectOpen}
              currencyExchangeRate={currencyExchangeRate}
              currencyPrefix={currencyPrefix}
              menuPosition="absolute"
              watchCurrency={watchCurrency}
            />
          </td>

          {/*Description*/}
          <td className="py-1 px-2">
            <InputField
              inputClassName="dark:bg-transparent dark:text-gray-200 border-0 border-b border-gray-300 dark:border-gray-700 p-0 w-full inline-flex focus:ring-0 focus:border-blue-500 text-sm"
              name={registerItemDescriptionField.name}
              onChange={registerItemDescriptionField.onChange}
              ref={registerItemDescriptionField.ref}
              type="text"
            />
          </td>

          {/*Qty*/}
          <td className="py-1 px-2">
            <InputField
              disabled={
                !currentUser.hasPermissions(
                  ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_ITEM_QUANTITY
                )
              }
              error={taskItemQtyValidationErrorMessage}
              inputClassName="dark:bg-transparent dark:text-gray-200 border-0 border-b border-gray-300 dark:border-gray-700 p-0 w-px min-w-12 inline-flex focus:ring-0 focus:border-blue-500 text-sm"
              min={1}
              name={registerItemQuantityField.name}
              onChange={(e) => {
                registerItemQuantityField.onChange(e);

                const input = e.target as HTMLInputElement;

                input.style.width = '0';
                input.style.width = input.scrollWidth + 'px';
              }}
              ref={registerItemQuantityField.ref}
              showErrorIcon={false}
              step={1}
              type="number"
            />
          </td>

          {/*Price*/}
          <td className="py-1 px-2">
            <InputField
              disabled={
                !selectedItemTypeId ||
                !currentUser.hasPermissions(
                  ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_ITEM_PRICE
                )
              }
              error={taskItemPriceValidationErrorMessage}
              inputClassName="dark:bg-transparent dark:text-gray-200 border-0 border-b border-gray-300 dark:border-gray-700 p-0 w-px min-w-20 inline-flex focus:ring-0 focus:border-blue-500 text-sm"
              min={0}
              name={registerItemPriceField.name}
              onChange={(e) => {
                registerItemPriceField.onChange(e);

                const input = e.target as HTMLInputElement;

                input.style.width = '0';
                input.style.width = input.scrollWidth + 'px';
              }}
              ref={registerItemPriceField.ref}
              showErrorIcon={false}
              step="any"
              type="number"
            />
          </td>

          {/*Subtotal*/}
          <td className="py-1 px-2">
            <div className="text-sm text-right">
              <MoneyHelper
                value={watchTaskItemSubtotal(taskIndex, index)}
                currency={currencyPrefix}
              />
            </div>
          </td>

          <td className="py-1 pr-4 sm:pr-6 pl-2 w-px sticky right-0 focus-within:z-5 bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90">
            <CheckPermissions
              action={
                ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_REMOVE_ITEM_BUTTON
              }
            >
              <div className="flex justify-end leading-none">
                <ConfirmModalButton
                  className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -my-0.5 text-gray-400 dark:text-gray-600"
                  icon={IconsEnum.TRASH_SOLID}
                  iconClassName="h-4 w-4"
                  i18nText={
                    itemsKeys.areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction
                  }
                  i18nSubmitText={words.ok}
                  onSubmit={handleRemoveItem}
                />
              </div>
            </CheckPermissions>
          </td>
        </tr>
      )}
    </Draggable>
  );
}

export default CreateProjectInTeamFormItemsTableRow;
