import { DragDropContext } from '@hello-pangea/dnd';
import first from 'lodash/first';

import { CreateProjectInTeamFormItemsTableHandle } from '../CreateProjectInTeamFormItemsTable/CreateProjectInTeamFormItemsTable';
import { MoneyType } from '../../../../../../../types';

import { useCreateProjectInTeamFormTasksList } from '../CreateProjectInTeamFormTasksList/hooks/useCreateProjectInTeamFormTasksList';

import { CreateProjectInTeamFormItemsTable } from '../CreateProjectInTeamFormItemsTable';

export type CreateProjectInTeamFormSingleTaskItemsTableHandle = {
  removeAllTasksItems: () => void;
};

interface CreateProjectInTeamFormSingleTaskItemsTableProps {
  currencyExchangeRate: MoneyType;
  currencyPrefix: string;
  watchProjectTotal?: number;
}

function CreateProjectInTeamFormSingleTaskItemsTable({
  currencyExchangeRate,
  currencyPrefix,
  watchProjectTotal
}: CreateProjectInTeamFormSingleTaskItemsTableProps) {
  const { getTasksRefsMap, handleDragItemEnd, tasksFields } =
    useCreateProjectInTeamFormTasksList();

  const taskField = first(tasksFields);

  return (
    <DragDropContext onDragEnd={handleDragItemEnd}>
      <div className="space-y-8 px-2">
        <CreateProjectInTeamFormItemsTable
          taskFieldId={taskField?.id}
          taskIndex={0}
          ref={(node: CreateProjectInTeamFormItemsTableHandle) => {
            const map = getTasksRefsMap();

            if (node) {
              map.set(taskField.id, node);
              return;
            }

            map.delete(taskField.id);
          }}
          currencyExchangeRate={currencyExchangeRate}
          currencyPrefix={currencyPrefix}
          watchProjectTotal={watchProjectTotal}
          withProjectTotal
        />
      </div>
    </DragDropContext>
  );
}

export default CreateProjectInTeamFormSingleTaskItemsTable;
