import { useItemDueDateValidationRules } from '../../../../../../common/forms/hooks/useItemDueDateValidationRules';

import { formsErrors } from '../../../../../../../locales/keys';

import { ProjectsPermissions } from '../../../../../projectsConstants';

const nameRules = { required: formsErrors.projectName.required };

const teamNanoIdRules = { required: formsErrors.client.required };

function useCreateProjectInTeamValidationRules() {
  const implementationDateRules = useItemDueDateValidationRules({
    cantBeBlank: false,
    action: ProjectsPermissions.CREATE_PROJECT_IMPLEMENTATION_TIME
  });

  return {
    implementationDateRules,
    nameRules,
    teamNanoIdRules
  };
}

export default useCreateProjectInTeamValidationRules;
