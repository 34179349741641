import { gql } from 'graphql-request';

import {
  ItemTypeCreatedAt,
  ItemTypeDescription,
  ItemTypeID,
  ItemTypeImageUrl,
  ItemTypeName,
  ItemTypeNanoID,
  ItemTypeNewClientDefaultAt,
  ItemTypePrice,
  ItemTypeUpdatedAt,
  ItemTypeUUID,
  ItemTypeViewPrice,
  ItemTypeViewPriceCurrency
} from '../itemTypesTypes';
import {
  ItemCategoryID,
  ItemCategoryName,
  ItemCategoryNanoID
} from '../../itemCategories/itemCategoriesTypes';
import { TeamName, TeamNanoID } from '../../teams/teamsTypes';
import { ID, MayBe } from '../../../types';
import { UserImageFile, UserName, UserNanoID } from '../../users/usersTypes';
import {
  ItemTagID,
  ItemTagName,
  ItemTagPosition
} from '../../itemTags/itemTagsTypes';

export interface FetchItemTypesQueryResponse {
  id: ItemTypeID;
  uuid: ItemTypeUUID;
  nanoId: ItemTypeNanoID;
  createdAt: ItemTypeCreatedAt;
  updatedAt: ItemTypeUpdatedAt;
  name: ItemTypeName;
  description: ItemTypeDescription;
  newClientDefaultAt: ItemTypeNewClientDefaultAt;
  imageUrl: ItemTypeImageUrl;
  price: ItemTypePrice;
  viewPrice: ItemTypeViewPrice;
  viewPriceCurrency: ItemTypeViewPriceCurrency;
  user: {
    nanoId: UserNanoID;
    name: UserName;
    image: {
      file: UserImageFile;
    } | null;
  };
  itemCategory: {
    id: ItemCategoryID;
    name: ItemCategoryName;
    nanoId: ItemCategoryNanoID;
  } | null;
  company: MayBe<{
    nanoId: TeamNanoID;
    name: TeamName;
  }>;
  itemTypeItemTags: {
    id: ID;
    itemTag: {
      id: ItemTagID;
      name: ItemTagName;
    };
    position: ItemTagPosition;
  }[];
}

export const FETCH_ITEM_TYPES_QUERY = gql`
  query ItemTypes(
    $filters: ItemTypesFilters
    $limit: Int
    $offset: Int
    $sort: [ItemTypesSortEnum!]
  ) {
    itemTypes(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      nodes {
        id
        uuid
        nanoId
        createdAt
        updatedAt
        name
        description
        newClientDefaultAt
        imageUrl
        price
        viewPrice
        viewPriceCurrency
        user {
          nanoId
          name
          image {
            file
          }
        }
        itemCategory {
          id
          name
          nanoId
        }
        company {
          nanoId
          name
        }
        itemTypeItemTags {
          id
          itemTag {
            id
            name
          }
          position
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
