import { useCallback, useRef, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import forEach from 'lodash/forEach';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormFields,
  CreateProjectInTeamsSplitPart
} from '../../../../CreateProjectInTeamForm.types';

interface CreateProjectInTeamFormSplitPartsInputOptions {
  control: Control<CreateProjectInTeamFormData>;
  watchSplitParts: () => CreateProjectInTeamsSplitPart[];
  setSplitPartValue: (partIndex: number, partValue: number) => void;
  setFocusSplitPart: (index: number) => void;
}

function useCreateProjectInTeamFormSplitPartsInput({
  control,
  watchSplitParts,
  setSplitPartValue,
  setFocusSplitPart
}: CreateProjectInTeamFormSplitPartsInputOptions) {
  const placeholderIndex = useRef(0);

  const [placeholder, setPlaceholder] = useState(100);

  const { fields, append, remove, update } = useFieldArray<
    CreateProjectInTeamFormData,
    CreateProjectInTeamFormFields.SPLIT_PARTS
  >({
    control,
    name: CreateProjectInTeamFormFields.SPLIT_PARTS
  });

  const handleChange = useCallback(() => {
    const parts = watchSplitParts();

    let sumParts = 0;

    let isRemove = false;
    let isUpdateCheck = true;

    placeholderIndex.current = -1;

    forEach(parts, (field, index) => {
      const partValue = +field.partValue;

      sumParts += partValue;

      if (field.partValue === '' && placeholderIndex.current === -1) {
        placeholderIndex.current = index;
      }

      if (
        isRemove ||
        (!partValue && parts.length > 1 && placeholderIndex.current !== index)
      ) {
        remove(index);
      }

      if (isUpdateCheck && sumParts > 100) {
        setSplitPartValue(index, 100 - (sumParts - partValue));
        isRemove = true;
        isUpdateCheck = false;
      }
    });

    if (sumParts < 100 && placeholderIndex.current === -1) {
      append({ partValue: '' });
      placeholderIndex.current = parts.length;

      setTimeout(() => setFocusSplitPart(parts.length), 0);
    }

    setPlaceholder(100 - sumParts);
  }, [append, watchSplitParts, remove, update]);

  return {
    fields,
    handleChange,
    placeholderIndex,
    placeholder,
    setPlaceholder
  };
}

export default useCreateProjectInTeamFormSplitPartsInput;
