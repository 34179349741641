import { useMemo } from 'react';
import filter from 'lodash/filter';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';

import {
  ClassName,
  I18nText,
  IsDisabled,
  IsFetched
} from '../../../../../types';
import { UserID } from '../../../usersTypes';
import { UsersAvatarsModalButtonUser } from './UsersAvatarsModalButton.types';

import { UsersSimpleModalButton } from '../../../../users/components/buttons/UsersSimpleModalButton';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { UserAvatar } from '../../../../common/helpers/UserAvatar';

interface UsersAvatarsModalButtonProps {
  allAvatarsSameSize?: boolean;
  avatarsCount?: number;
  disabled?: IsDisabled;
  i18nModalTitle: I18nText;
  ignoreUser?: UsersAvatarsModalButtonUser;
  onRemoveUser?: (id: UserID) => void;
  users: UsersAvatarsModalButtonUser[];
  usersFetched?: IsFetched;
  withFinanceRole?: boolean;
  className?: ClassName;
}

function UsersAvatarsModalButton({
  allAvatarsSameSize = false,
  avatarsCount = 3,
  disabled,
  i18nModalTitle,
  ignoreUser,
  onRemoveUser,
  users,
  usersFetched = true,
  withFinanceRole = false,
  className
}: UsersAvatarsModalButtonProps) {
  const actualUsers = useMemo<UsersAvatarsModalButtonUser[]>(
    () =>
      sortBy(
        ignoreUser
          ? filter(users, (user) => user.nanoId !== ignoreUser.nanoId)
          : users,
        'nanoId'
      ),
    [ignoreUser, users]
  );

  const restUsersCount = size(actualUsers) - avatarsCount;

  if (isEmpty(actualUsers)) {
    return null;
  }

  return (
    <UsersSimpleModalButton
      buttonChildren={
        <LoadingSkeleton
          loaded={usersFetched}
          count={1}
          width={48}
          className="m-0 h-6"
          skeletonClassName="flex"
        >
          <div className="flex items-center space-x-1.5">
            <div className="flex items-center flex-shrink-0 -space-x-1">
              {!allAvatarsSameSize && (
                <UserAvatar type="xl" user={first(actualUsers)} />
              )}

              {slice(actualUsers, allAvatarsSameSize ? 0 : 1, avatarsCount).map(
                (user) => (
                  <UserAvatar key={user.nanoId} type="xs" user={user} />
                )
              )}
            </div>

            {restUsersCount > 0 ? (
              <span className="flex-shrink-0 text-xs leading-5 font-medium">
                +{restUsersCount}
              </span>
            ) : null}
          </div>
        </LoadingSkeleton>
      }
      disabled={disabled}
      i18nModalTitle={i18nModalTitle}
      onRemoveUser={onRemoveUser}
      users={actualUsers}
      usersFetched={usersFetched}
      visibleUsersLimit={avatarsCount}
      withFinanceRole={withFinanceRole}
      className={className}
    />
  );
}

export default UsersAvatarsModalButton;
