import {
  CreateProjectInTeamGqlQuery,
  CreateProjectInTeamGqlStatus,
  FetchProjectsCacheKey,
  ProjectDescription,
  ProjectFileAttachmentIDs,
  ProjectGqlError,
  ProjectImplementationDate,
  ProjectName,
  ProjectNanoID,
  ProjectTeamNanoID,
  ProjectUUID
} from '../../projectsTypes';
import { TaskName } from '../../../tasks/tasksTypes';
import {
  ItemDescription,
  ItemItemTypeID,
  ItemPrice
} from '../../../items/itemsTypes';
import { UserID } from '../../../users/usersTypes';
import { AvBillingInfoNotes } from '../../../avBillingInfos/avBillingInfosTypes';
import { BillingInfoID } from '../../../billingInfos/billingInfosTypes';
import { Currencies } from '../../../../types';
import { InvoicePaymentMethods } from '../../../invoices/invoicesTypes';
import { TeamTerms } from '../../../teams/teamsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateProjectInTeamOptions {
  query: CreateProjectInTeamGqlQuery;
  cacheKeys?: FetchProjectsCacheKey[];
}

interface CreateProjectInTeamRecordResponse {
  uuid: ProjectUUID;
}

export interface CreateProjectInTeamResponse {
  createProjectInTeam: {
    status: CreateProjectInTeamGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: CreateProjectInTeamRecordResponse;
    errors: CreateProjectInTeamError;
  };
}
interface CreateProjectInTeamInputTaskItem {
  itemTypeId: ItemItemTypeID;
  description?: ItemDescription;
  price: ItemPrice;
}

type CreateProjectInTeamInputTaskItems = CreateProjectInTeamInputTaskItem[];

interface CreateProjectInTeamInputTask {
  name: TaskName;
  items?: CreateProjectInTeamInputTaskItems;
}

export type CreateProjectInTeamInputTasks = CreateProjectInTeamInputTask[];

export interface CreateProjectInTeamInput {
  billingInfoId?: BillingInfoID;
  createInvoice?: boolean;
  description?: ProjectDescription | null;
  fileAttachmentIds?: ProjectFileAttachmentIDs | null;
  implementationDate?: ProjectImplementationDate | null;
  name: ProjectName;
  nanoId: ProjectNanoID;
  notes?: AvBillingInfoNotes;
  ownerId?: UserID;
  paymentMethod?: InvoicePaymentMethods;
  tasks?: CreateProjectInTeamInputTasks;
  teamNanoId: ProjectTeamNanoID;
  terms?: TeamTerms;
  preferredCurrency?: Currencies;
}

export interface CreateProjectInTeamError {
  description: ProjectGqlError;
  fileAttachmentIds: ProjectGqlError;
  fullMessages: ProjectGqlError;
  implementationDate: ProjectGqlError;
  name: ProjectGqlError;
  nanoId: ProjectGqlError;
  teamNanoId: ProjectGqlError;
}

const action = 'createProjectInTeam';

function useCreateProjectInTeam({
  query,
  cacheKeys
}: CreateProjectInTeamOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateProjectInTeamInput,
    CreateProjectInTeamResponse,
    CreateProjectInTeamError,
    CreateProjectInTeamRecordResponse
  >({ action, cacheKeys, query });

  return {
    createProjectInTeamData: createQueryData,
    createProjectInTeamError: createQueryError,
    createProjectInTeamLoading: createQueryLoading,
    createProjectInTeamErrorMessage: createQueryErrorMessage,
    createProjectInTeam: createQuery,
    createProjectInTeamReset: createQueryReset
  };
}

export default useCreateProjectInTeam;
