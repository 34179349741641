import { gql } from 'graphql-request';

import {
  TeamAccountReceivables,
  TeamID,
  TeamNanoID,
  TeamUUID
} from '../teamsTypes';

export interface FetchTeamAccountReceivablesQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  accountReceivables: TeamAccountReceivables;
}

export const FETCH_TEAM_ACCOUNT_RECEIVABLES_QUERY = gql`
  query TeamAccountReceivables($uuid: ID!) {
    team(uuid: $uuid) {
      id
      uuid
      nanoId
      accountReceivables
    }
  }
`;
