import { useCallback, useEffect, useMemo } from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { useTeamsUsers } from '../../../../../../hooks/useTeamsUsers';
import { useUser } from '../../../../../../../users/hooks/useUser';

import { UserUUID } from '../../../../../../../users/usersTypes';
import {
  MultiSelectChangeCallbackType,
  MultiSelectDataType,
  MultiSelectExtendedDataType,
  MultiSelectInputChangeCallbackType
} from '../../../../../../../../helpers/MultiSelect/types';
import {
  FETCH_SELECT_FIELD_USER_QUERY,
  FetchSelectFieldUserQueryResponse
} from '../../../../../../../users/queries/fetchSelectFieldUser.query';
import {
  FETCH_TEAMS_USERS_QUERY,
  FetchTeamsUsersQueryResponse
} from '../../../../../../queries/fetchTeamsUsers.query';
import {
  FetchTeamsUsersCacheKey,
  TeamsUserUserID
} from '../../../../../../teamsUsersTypes';
import { TeamsUserIdSelectFieldTeamsUserType } from '../../TeamsUserIdSelectFieldControl.types';
import { TeamNanoID } from '../../../../../../../teams/teamsTypes';

import { UserCache } from '../../../../../../../users/UserCache';

export interface TeamsUserIdSelectFieldControlOptions {
  afterChange?: (teamsUser: TeamsUserIdSelectFieldTeamsUserType) => void;
  cacheKey: FetchTeamsUsersCacheKey;
  name: string;
  onChange: (value: TeamsUserUserID) => void;
  teamNanoId?: TeamNanoID;
  value: TeamsUserUserID;
}

function useTeamsUserIdSelectFieldControl({
  afterChange,
  cacheKey,
  name,
  onChange,
  teamNanoId,
  value
}: TeamsUserIdSelectFieldControlOptions) {
  const {
    teamsUsers,
    teamsUsersLoading,
    teamsUsersError,
    changeTeamsUsersFilters,
    loadMoreTeamsUsers,
    teamsUsersFetchingNextPage
  } = useTeamsUsers<FetchTeamsUsersQueryResponse>({
    cacheKey,
    query: FETCH_TEAMS_USERS_QUERY,
    initialFilters: { teamNanoId: { eq: teamNanoId } }
  });

  const {
    user: selectedUser,
    userLoading: selectedUserLoading,
    userError: selectedUserError
  } = useUser<FetchSelectFieldUserQueryResponse>({
    cacheKey: UserCache.selectedUserSelectFieldCacheKey(name),
    query: FETCH_SELECT_FIELD_USER_QUERY,
    uuid: value as UserUUID,
    options: {
      enabled: !isEmpty(value),
      enabledPlaceholder: !isEmpty(value)
    }
  });

  const data = useMemo<MultiSelectExtendedDataType[]>(() => {
    return teamsUsers.map((teamsUser) => ({
      value: teamsUser.user?.id,
      image: teamsUser.user?.image?.file,
      label: teamsUser.user?.fullName
    }));
  }, [teamsUsers]);

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) =>
      changeTeamsUsersFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      ),
    [changeTeamsUsersFilters]
  );

  const selectedValue = useMemo<MultiSelectExtendedDataType>(
    () =>
      selectedUser
        ? {
            value: selectedUser.id,
            image: selectedUser.image?.file,
            label: selectedUser.fullName
          }
        : null,
    [selectedUser]
  );

  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (updateValue: MultiSelectDataType) => {
      const updatedTeamsUserUserId = updateValue?.value as TeamsUserUserID;

      const updatedTeamsUser = find(
        teamsUsers,
        (teamsUser) => teamsUser.user?.id === updatedTeamsUserUserId
      );

      onChange(updatedTeamsUserUserId);
      afterChange?.(updatedTeamsUser?.user);
    },
    [afterChange, onChange, teamsUsers]
  );

  useEffect(() => {
    if (teamNanoId) {
      changeTeamsUsersFilters({ teamNanoId: { eq: teamNanoId } });
    }
  }, [teamNanoId, changeTeamsUsersFilters]);

  return {
    data,
    handleChange,
    handleInputChange,
    loadMoreTeamsUsers,
    selectedUserError,
    selectedUserLoading,
    selectedValue,
    teamsUsers,
    teamsUsersError,
    teamsUsersFetchingNextPage,
    teamsUsersLoading
  };
}

export default useTeamsUserIdSelectFieldControl;
