import { gql } from 'graphql-request';

import {
  ItemTagCreatedAt,
  ItemTagID,
  ItemTagName,
  ItemTagNanoID,
  ItemTagUpdatedAt,
  ItemTagUUID
} from '../itemTagsTypes';

export interface FetchSelectItemTagsQueryResponse {
  id: ItemTagID;
  uuid: ItemTagUUID;
  nanoId: ItemTagNanoID;
  createdAt: ItemTagCreatedAt;
  updatedAt: ItemTagUpdatedAt;
  name: ItemTagName;
}

export const FETCH_SELECT_ITEM_TAGS_QUERY = gql`
  query SelectItemTags(
    $filters: ItemTagsFilters
    $limit: Int
    $offset: Int
    $sort: [ItemTagsSortEnum!]
  ) {
    itemTags(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      nodes {
        id
        uuid
        nanoId
        createdAt
        updatedAt
        name
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
    }
  }
`;
