import { useCallback } from 'react';
import first from 'lodash/first';

import { Currencies, MoneyType } from '../../../../types';
import {
  FETCH_CURRENCY_REATES_QUERY,
  FetchCurrencyRatesQueryResponse
} from '../../queries/fetchCurrencyRates.query';

import { useCurrencyRates } from '../useCurrencyRates';

import { CurrencyRateCache } from '../../CurrencyRateCache';
import { amountMultiply } from '../../../../utils/amountMultiply';
import { amountDivide } from '../../../../utils/amountDivide';

function useConvertCurrencyRates() {
  const { currencyRates, currencyRatesLoading, currencyRatesError } =
    useCurrencyRates<FetchCurrencyRatesQueryResponse>({
      initialLimit: 1,
      cacheKey: CurrencyRateCache.indexCacheKey(),
      query: FETCH_CURRENCY_REATES_QUERY
    });

  const item = first(currencyRates);

  const convertCurrencyFromUsd = useCallback(
    (value: MoneyType, toCurrency: Currencies) =>
      amountMultiply(value, item?.data?.[toCurrency] || 1),
    [item?.data]
  );

  const convertCurrencyToUsd = useCallback(
    (value: MoneyType, fromCurrency: Currencies) =>
      amountDivide(value, item?.data?.[fromCurrency] || 1),
    [item?.data]
  );

  const convertCurrencyCrossExchangeUsd = useCallback(
    (value: MoneyType, fromCurrency: Currencies, toCurrency: Currencies) => {
      if (fromCurrency === toCurrency) return value;

      return convertCurrencyFromUsd(
        convertCurrencyToUsd(value, fromCurrency),
        toCurrency
      );
    },
    [convertCurrencyFromUsd, convertCurrencyToUsd]
  );

  return {
    currencyRatesError,
    currencyRatesLoading,
    currencyRatesId: item?.id,
    currencyRatesData: item?.data,
    convertCurrencyFromUsd,
    convertCurrencyToUsd,
    convertCurrencyCrossExchangeUsd
  };
}

export default useConvertCurrencyRates;
