import Dinero from 'dinero.js';
import map from 'lodash/map';
import round from 'lodash/round';

const defaultRatios = [50, 50];

function amountAllocate(
  amount: number,
  ratios: number[] = defaultRatios
): number[] {
  const dineroAmountDue = Dinero({
    amount: round(amount * 100)
  });

  const parts = dineroAmountDue.allocate(ratios);

  return map(parts, (part) => part.getAmount() / 100.0);
}

export default amountAllocate;
