import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { ClassName, I18nText } from '../../types';
import { IconsEnum } from '../../assets/icons/types';

import { PureIconButtonHelper } from '../buttons/PureIconButtonHelper';

import { Translate } from '../../helpers/Translate';

import { words } from '../../locales/keys';

interface AccordionHelperProps {
  children: ReactNode;
  className?: ClassName;
  i18nText: I18nText;
  isAccordionOpen?: boolean;
}

function AccordionHelper({
  children,
  className,
  i18nText,
  isAccordionOpen
}: AccordionHelperProps) {
  const [isOpen, setIsOpen] = useState<boolean>(isAccordionOpen);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  useEffect(() => setIsOpen(isAccordionOpen), [isAccordionOpen]);

  return (
    <div
      className={
        className || 'p-2 sm:p-4 sm:rounded-md bg-gray-50 dark:bg-gray-800'
      }
    >
      <div className="flex gap-2 items-center">
        <div className="text-xs font-semibold uppercase">
          <Translate id={i18nText} />
        </div>

        <div className="border-b dark:border-gray-700 flex-1 h-px" />

        <PureIconButtonHelper
          icon={isOpen ? IconsEnum.CHEVRON_UP : IconsEnum.CHEVRON_DOWN_SOLID}
          className="text-xs flex items-center hover:underline text-gray-500"
          i18nText={isOpen ? words.hide : words.show}
          iconClassName="h-4 w-4"
          onClick={toggleIsOpen}
        />
      </div>

      {isOpen && children}
    </div>
  );
}

export default AccordionHelper;
