import { FetchItemTagsSortTypes } from './itemTagsTypes';

export const INITIAL_ITEM_TAGS_FILTERS = {};
export const INITIAL_ITEM_TAGS_PAGE = 1;
export const INITIAL_ITEM_TAGS_SORT = [FetchItemTagsSortTypes.CREATED_AT_DESC];
export const INITIAL_ITEM_TAGS_LIMIT = 100;

export const enum ItemTagsPermissions {
  READ_ITEM_TAGS_INDEX_PAGE = 'read_item_tags_index_page',
  READ_ITEM_TAGS_TABLE_SELECTED_FIELD = 'read_item_tags_table_selected_field',
  READ_ITEM_TAGS_TABLE_NAME_FIELD = 'read_item_tags_table_name_field',
  READ_ITEM_TAGS_TABLE_USER_FIELD = 'read_item_tags_table_user_field',
  READ_ITEM_TAGS_TABLE_CREATED_AT_FIELD = 'read_item_tags_table_created_at_field',
  READ_ITEM_TAGS_TABLE_DROPDOWN_FIELD = 'read_item_tags_table_dropdown_field',
  READ_ITEM_TAGS_TABLE_UPDATE_FIELD = 'read_item_tags_table_update_field',
  READ_ITEM_TAGS_TABLE_DELETE_FIELD = 'read_item_tags_table_delete_field',
  READ_ITEM_TAGS_CREATE_ITEM_TAG_BUTTON = 'read_item_tags_create_item_tag_button',
  READ_ITEM_TAGS_FOR_ITEM_NAME_FEATURE = 'read_item_tags_for_item_name_feature'
}
