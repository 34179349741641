import { Translate } from '../../../../../../../../../helpers/Translate';

import { itemsKeys } from '../../../../../../../../../locales/keys';

function CreateProjectInTeamFormItemsTableHeader() {
  return (
    <thead>
      <tr>
        <th className="min-w-8" />
        <th className="py-1 px-2 text-xs text-gray-500 font-normal text-left w-60 min-w-60">
          <Translate id={itemsKeys.name} />
        </th>
        <th className="py-1 px-2 text-xs text-gray-500 font-normal text-left min-w-32 max-w-60">
          <Translate id={itemsKeys.description} />
        </th>
        <th className="py-1 px-2 text-xs text-gray-500 font-normal text-left w-px min-w-12">
          <Translate id={itemsKeys.qty} />
        </th>
        <th className="py-1 px-2 text-xs text-gray-500 font-normal text-left w-px min-w-20">
          <Translate id={itemsKeys.price} />
        </th>
        <th className="py-1 px-2 text-xs text-gray-500 font-normal text-right w-[10ch]">
          <Translate id={itemsKeys.subTotal} />
        </th>
        <th className="py-1 px-2 text-xs text-gray-500 font-normal text-left w-px sticky right-0 bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90" />
      </tr>
    </thead>
  );
}

export default CreateProjectInTeamFormItemsTableHeader;
