import { ForwardedRef, forwardRef } from 'react';

import { MoneyType } from '../../../../../../../../../types';
import { CreateProjectInTeamFormData } from '../../../../CreateProjectInTeamForm.types';
import { CreateProjectInTeamFormItemsTableHandle } from '../../../CreateProjectInTeamFormItemsTable/CreateProjectInTeamFormItemsTable';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { useCreateProjectInTeamFormTasksListItem } from './hooks/useCreateProjectInTeamFormTasksListItem';

import { CreateProjectInTeamFormItemsTable } from '../../../CreateProjectInTeamFormItemsTable';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { ConfirmModalButton } from '../../../../../../../../../helpers/buttons/ConfirmModalButton';
import { InputField } from '../../../../../../../../../helpers/FormFields/InputField';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';
import { UsersAvatarsField } from '../../../../../../../../../helpers/FormFields/UsersAvatarsField';

import {
  itemsKeys,
  tasksKeys,
  words
} from '../../../../../../../../../locales/keys';

import { ProjectsPermissions } from '../../../../../../../projectsConstants';

interface CreateProjectInTeamFormTasksListItemProps {
  disabled?: boolean;
  index: number;
  removeTask: (index: number) => void;
  taskFieldId: string;
  currencyExchangeRate: MoneyType;
  currencyPrefix: string;
}

function CreateProjectInTeamFormTasksListItem(
  {
    disabled,
    index,
    removeTask,
    taskFieldId,
    currencyExchangeRate,
    currencyPrefix
  }: CreateProjectInTeamFormTasksListItemProps,
  ref: ForwardedRef<CreateProjectInTeamFormItemsTableHandle>
) {
  const {
    control,
    isOpen,
    toggleIsOpen,
    handleRemoveTask,
    t,
    itemsCount,
    registerTaskNameField,
    taskNameErrorMessage
  } = useCreateProjectInTeamFormTasksListItem({ index, removeTask });

  return (
    <>
      <div className="space-y-4">
        <div>
          <CheckPermissions
            action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TASK_NAME}
          >
            <div className="flex gap-1 items-center">
              <InputField
                className="flex-1"
                disabled={disabled}
                error={taskNameErrorMessage}
                showErrorIcon={false}
                inputClassName="dark:bg-transparent dark:text-gray-200 border-0 border-b border-gray-300 dark:border-gray-700 px-0 py-1 w-full inline-flex focus:ring-0 focus:border-blue-500 text-base"
                name={registerTaskNameField.name}
                onChange={registerTaskNameField.onChange}
                ref={registerTaskNameField.ref}
                type="text"
              />

              <CheckPermissions
                action={
                  ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_REMOVE_TASK_BUTTON
                }
              >
                <ConfirmModalButton
                  className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -my-0.5 text-gray-400 dark:text-gray-600"
                  icon={IconsEnum.TRASH_SOLID}
                  iconClassName="h-4 w-4"
                  i18nText={
                    tasksKeys.areYouSureYouWantToDeleteThisTaskYouWillNotBeAbleToUndoThisAction
                  }
                  i18nSubmitText={words.ok}
                  onSubmit={handleRemoveTask}
                />
              </CheckPermissions>
            </div>
          </CheckPermissions>

          <CheckPermissions
            action={
              ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TOGGLE_ITEMS_BUTTON
            }
          >
            <div className="text-gray-500 text-xs mt-2">
              <PureIconButtonHelper
                className="p-0 space-x-1 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline"
                icon={
                  isOpen
                    ? IconsEnum.CHEVRON_DOWN_SOLID
                    : IconsEnum.CHEVRON_RIGHT
                }
                onClick={toggleIsOpen}
                text={`${itemsCount} ${t(
                  itemsCount !== 1
                    ? itemsKeys.pluralLowerCase
                    : itemsKeys.singularLowerCase
                )}`}
              />
            </div>
          </CheckPermissions>
        </div>

        {isOpen && (
          <CheckPermissions
            action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_ITEMS}
          >
            <CreateProjectInTeamFormItemsTable
              taskFieldId={taskFieldId}
              taskIndex={index}
              ref={ref}
              currencyExchangeRate={currencyExchangeRate}
              currencyPrefix={currencyPrefix}
            />
          </CheckPermissions>
        )}
      </div>

      <CheckPermissions
        action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TASK_USER_IDS}
      >
        <UsersAvatarsField<CreateProjectInTeamFormData>
          control={control}
          disabled={disabled}
          name={`tasks.${index}.userIds`}
          i18nLabel={tasksKeys.invitations}
        />
      </CheckPermissions>
    </>
  );
}

export default forwardRef<
  CreateProjectInTeamFormItemsTableHandle,
  CreateProjectInTeamFormTasksListItemProps
>(CreateProjectInTeamFormTasksListItem);
