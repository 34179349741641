import { gql } from 'graphql-request';

export const CREATE_PROJECT_IN_TEAM_QUERY = gql`
  mutation CreateProjectInTeam(
    $billingInfoId: ID
    $createInvoice: Boolean
    $description: String
    $fileAttachmentIds: [ID!]
    $implementationDate: DateTime
    $name: String!
    $nanoId: NanoId
    $notes: String
    $ownerId: ID
    $paymentMethod: String
    $tasks: JSON
    $teamNanoId: NanoId!
    $terms: String
    $preferredCurrency: String
  ) {
    createProjectInTeam(
      input: {
        billingInfoId: $billingInfoId
        createInvoice: $createInvoice
        description: $description
        fileAttachmentIds: $fileAttachmentIds
        implementationDate: $implementationDate
        name: $name
        nanoId: $nanoId
        notes: $notes
        ownerId: $ownerId
        paymentMethod: $paymentMethod
        tasks: $tasks
        teamNanoId: $teamNanoId
        terms: $terms
        preferredCurrency: $preferredCurrency
      }
    ) {
      status
      recordUuid
      recordNanoId
      record {
        id
        uuid
        nanoId
      }
      errors {
        name
        nanoId
        description
        implementationDate
        fullMessages
      }
    }
  }
`;
