import { useTeamsUserIdSelectFieldControl } from './hoooks/useTeamsUserIdSelectFieldControl';

import {
  TeamsUserIdSelectFieldControlProps,
  TeamsUserIdSelectFieldControlRequiredProps
} from './TeamsUserIdSelectFieldControl.types';

import { MultiSelect } from '../../../../../../helpers/MultiSelect';

function TeamsUserIdSelectFieldControl({
  afterChange,
  cacheKey,
  classNamePrefix,
  closeMenuOnSelect = true,
  disabled = false,
  emptyValue = null,
  error,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  name,
  onChange,
  placeholder = null,
  teamNanoId,
  value
}: TeamsUserIdSelectFieldControlProps &
  TeamsUserIdSelectFieldControlRequiredProps) {
  const {
    data,
    handleChange,
    handleInputChange,
    loadMoreTeamsUsers,
    selectedUserError,
    selectedUserLoading,
    selectedValue,
    teamsUsersError,
    teamsUsersFetchingNextPage,
    teamsUsersLoading
  } = useTeamsUserIdSelectFieldControl({
    afterChange,
    cacheKey,
    name,
    onChange,
    teamNanoId,
    value
  });

  return (
    <>
      <MultiSelect
        classNamePrefix={classNamePrefix}
        closeMenuOnSelect={closeMenuOnSelect}
        data={data}
        disabled={disabled}
        emptyValue={emptyValue}
        error={error || teamsUsersError || selectedUserError}
        i18nLabel={i18nLabel}
        i18nPlaceholder={i18nPlaceholder}
        inputWrapperClassName={inputWrapperClassName}
        isClearable={isClearable}
        isLoading={teamsUsersFetchingNextPage}
        isSearchable={false}
        labelClassName={labelClassName}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        multi={false}
        name={name}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={loadMoreTeamsUsers}
        optionsLoading={teamsUsersLoading || selectedUserLoading}
        placeholder={placeholder}
        value={selectedValue}
      />
    </>
  );
}

export default TeamsUserIdSelectFieldControl;
