import { CompanyNanoID } from '../companies/companiesTypes';

export class ItemTagCache {
  static showCacheKey() {
    return 'item-tag';
  }

  static indexCacheKey() {
    return 'item-tags';
  }

  static companyItemTagsCacheKey(companyNanoId: CompanyNanoID) {
    return `company-${companyNanoId}-item-tags`;
  }

  static selectFieldCacheKey() {
    return 'item-tag-select-field';
  }
}
