import { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { useCreateProjectInTeamFormContext } from '../../hooks/useCreateProjectInTeamFormContext';
import { useCreateProjectInTeamFormItemsTable } from './hooks/useCreateProjectInTeamFormItemsTable';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { CreateProjectInTeamFormDataTaskItem } from '../../CreateProjectInTeamForm.types';
import { FetchItemTypesCacheKey } from '../../../../../../itemTypes/itemTypesTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { MoneyType, PositionStyleEnum } from '../../../../../../../types';
import { PageNanoID } from '../../../../../../pages/pagesTypes';

import { CreateItemTypeModalButton } from '../../../../../../itemTypes/components/modalButtons/CreateItemTypeModalButton';
import { CreateProjectInTeamFormItemsTableHeader } from './components/CreateProjectInTeamFormItemsTableHeader';
import { CreateProjectInTeamFormItemsTableRow } from './components/CreateProjectInTeamFormItemsTableRow';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { ShowPageModalButton } from '../../../../../../pages/components/modalButtons/ShowPageModalButton';
import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { ItemTypeCache } from '../../../../../../itemTypes/ItemTypeCache';
import {
  itemsKeys,
  stringsKeys,
  tasksKeys,
  words
} from '../../../../../../../locales/keys';
import { ProjectsPermissions } from '../../../../../projectsConstants';

import { ITEM_TYPES_HELP_PAGE_NANOID } from '../../../../../../../config';

export type CreateProjectInTeamFormItemsTableHandle = {
  getItem: (itemIndex: number) => CreateProjectInTeamFormDataTaskItem;
  insertItem: (
    itemIndex: number,
    item: CreateProjectInTeamFormDataTaskItem
  ) => void;
  moveItem: (fromIndex: number, toIndex: number) => void;
  removeItem: (index: number) => void;
  removeItems: () => void;
};

interface CreateProjectInTeamFormItemsTableWithProjectTotalProps {
  watchProjectTotal: number;
  withProjectTotal: boolean;
}

interface CreateProjectInTeamFormItemsTableWithoutProjectTotalProps {
  watchProjectTotal?: never;
  withProjectTotal?: never;
}

interface CreateProjectInTeamFormItemsTableBaseProps {
  taskFieldId: string;
  taskIndex: number;
  currencyExchangeRate: MoneyType;
  currencyPrefix: string;
}

type CreateProjectInTeamFormItemsTableProps =
  CreateProjectInTeamFormItemsTableBaseProps &
    (
      | CreateProjectInTeamFormItemsTableWithProjectTotalProps
      | CreateProjectInTeamFormItemsTableWithoutProjectTotalProps
    );

function CreateProjectInTeamFormItemsTable(
  {
    taskFieldId,
    taskIndex,
    currencyExchangeRate,
    currencyPrefix,
    watchProjectTotal,
    withProjectTotal
  }: CreateProjectInTeamFormItemsTableProps,
  ref: ForwardedRef<CreateProjectInTeamFormItemsTableHandle>
) {
  const currentUser = useCurrentUser();

  const { control, selectedTeamNanoId, watchTaskTotal } =
    useCreateProjectInTeamFormContext();

  const createItemTypeCacheKeys = useMemo<FetchItemTypesCacheKey[]>(
    () => [
      ItemTypeCache.selectFieldCacheKey(),
      ItemTypeCache.companyItemTypesCacheKey(selectedTeamNanoId),
      ItemTypeCache.companySelectCacheKey(selectedTeamNanoId)
    ],
    [selectedTeamNanoId]
  );

  const {
    appendItem,
    appendItemWithItemTypeId,
    containerRef,
    getItem,
    handleCloseItemTypeIdsSelect,
    insertItem,
    isItemTypeIdsSelectOpen,
    itemsFields,
    moveItem,
    removeItem,
    removeItems
  } = useCreateProjectInTeamFormItemsTable({
    control,
    taskIndex,
    currencyExchangeRate
  });

  useImperativeHandle(ref, () => ({
    getItem,
    insertItem,
    moveItem,
    removeItem,
    removeItems
  }));

  return (
    <div>
      <div ref={containerRef} className="-mx-4 sm:-mx-6">
        <table className="w-full" data-id="invoice-table">
          <CreateProjectInTeamFormItemsTableHeader />

          <Droppable droppableId={taskFieldId}>
            {(provided) => (
              <tbody
                className={cl({ 'my-1': isEmpty(itemsFields) })}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {itemsFields.map((itemField, index) => (
                  <CreateProjectInTeamFormItemsTableRow
                    getItem={getItem}
                    index={index}
                    isItemTypeIdsSelectOpen={isItemTypeIdsSelectOpen}
                    itemFieldId={itemField.id}
                    defaultItemTypeId={itemField.itemTypeId}
                    key={itemField.id}
                    onCloseItemTypeIdsSelect={handleCloseItemTypeIdsSelect}
                    removeItem={removeItem}
                    taskIndex={taskIndex}
                    currencyExchangeRate={currencyExchangeRate}
                    currencyPrefix={currencyPrefix}
                  />
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>

          <tfoot>
            <tr>
              <td className="relative pl-4 sm:pl-6 pr-2 w-px py-3" colSpan={3}>
                <span className="relative inline-flex rounded-md sm:flex">
                  <CheckPermissions
                    action={
                      ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_ADD_ITEM_BUTTON
                    }
                  >
                    <PureIconButtonHelper
                      className={cl(
                        'py-1.5 pl-1.5 pr-3 space-x-1 inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border-y border-l border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700',
                        currentUser.hasPermissions(
                          ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_NEW_ITEM_TYPE_BUTTON
                        )
                          ? 'rounded-l-md relative'
                          : 'rounded-md border-r'
                      )}
                      disabled={!selectedTeamNanoId}
                      i18nText={itemsKeys.add}
                      icon={IconsEnum.PLUS_SOLID}
                      iconClassName="h-5 w-5 mr-1"
                      onClick={appendItem}
                    />
                  </CheckPermissions>
                  <CheckPermissions
                    action={
                      ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_NEW_ITEM_TYPE_BUTTON
                    }
                  >
                    <TooltipDropdownHelper
                      buttonClassName="p-2 rounded-r-md border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 text-sm font-medium mr-1"
                      className="relative flex"
                      disabled={!selectedTeamNanoId}
                      dropdownPlacement={PopoverPlacement.BOTTOM_END}
                      icon={IconsEnum.CHEVRON_DOWN_SOLID}
                      popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
                      popoverPositionStyle={PositionStyleEnum.fixed}
                      tooltipI18nText={words.openOptions}
                      tooltipSingleton
                    >
                      <CreateItemTypeModalButton
                        afterCreate={appendItemWithItemTypeId}
                        cacheKeys={createItemTypeCacheKeys}
                        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                        companyNanoId={selectedTeamNanoId}
                        disabled={!selectedTeamNanoId}
                        i18nTextClassName="pr-2 mr-auto"
                      />
                    </TooltipDropdownHelper>
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_ITEM_TYPES_HELP_MODAL_BUTTON
                    }
                  >
                    <ShowPageModalButton
                      i18nTitle={stringsKeys.learnAboutItemTypes}
                      pageNanoId={ITEM_TYPES_HELP_PAGE_NANOID as PageNanoID}
                    />
                  </CheckPermissions>
                </span>
              </td>

              {withProjectTotal ? (
                <>
                  <td
                    className="p-2 text-right text-lg font-semibold"
                    colSpan={2}
                  >
                    <Translate id={words.total} />
                  </td>

                  <td
                    className="p-2 text-right text-lg font-semibold"
                    colSpan={1}
                  >
                    <MoneyHelper
                      value={watchProjectTotal}
                      currency={currencyPrefix}
                    />
                  </td>
                </>
              ) : (
                <>
                  <td className="p-2 text-sm" colSpan={2}>
                    <CheckPermissions
                      action={
                        ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TASK_TOTAL
                      }
                    >
                      <Translate id={tasksKeys.total} />
                    </CheckPermissions>
                  </td>
                  <td className="p-2 text-sm" colSpan={2}>
                    <CheckPermissions
                      action={
                        ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TASK_TOTAL
                      }
                    >
                      <MoneyHelper
                        value={watchTaskTotal(taskIndex)}
                        currency={currencyPrefix}
                      />
                    </CheckPermissions>
                  </td>
                </>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default forwardRef<
  CreateProjectInTeamFormItemsTableHandle,
  CreateProjectInTeamFormItemsTableProps
>(CreateProjectInTeamFormItemsTable);
