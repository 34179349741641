import isEmpty from 'lodash/isEmpty';

import { I18nText, IsDisabled } from '../../../../../types';
import { UserID } from '../../../../../main/users/usersTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useUsersAvatarsFieldControl } from '../../hooks/useUsersAvatarsFieldControl';

import { AddUsersModalButton } from '../AddUsersModalButton';
import { UsersAvatarsModalButton } from '../../../../../main/users/components/modalButtons/UsersAvatarsModalButton';

import { Translate } from '../../../../Translate';

import { tasksKeys } from '../../../../../locales/keys';

import { TooltipPlacement } from '../../../../tooltips/tooltipsConstants';

interface UsersAvatarsFieldControlProps {
  disabled?: IsDisabled;
  fieldName: string;
  i18nLabel?: I18nText;
  onChange: (value: UserID[]) => void;
  value?: UserID[];
}
function UsersAvatarsFieldControl({
  disabled,
  fieldName,
  i18nLabel,
  onChange,
  value
}: UsersAvatarsFieldControlProps) {
  const { users, userUuids, usersFetched, handleRemoveUser, handleAddUsers } =
    useUsersAvatarsFieldControl({
      disabled,
      fieldName,
      i18nLabel,
      onChange,
      value
    });

  return (
    <div>
      <div className="flex items-center gap-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          <Translate id={i18nLabel} />
        </label>

        <AddUsersModalButton
          className="py-0.5 pl-0.5 pr-0.5 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          disabled={disabled}
          icon={IconsEnum.PLUS}
          iconClassName="w-5 h-5"
          initialUserUuids={userUuids}
          onAddUsers={handleAddUsers}
          tooltipI18nText={tasksKeys.inviteUser}
          tooltipPlacement={TooltipPlacement.TOP}
        />
      </div>

      {!isEmpty(value) && (
        <div className="mt-1">
          <UsersAvatarsModalButton
            allAvatarsSameSize
            avatarsCount={12}
            disabled={disabled}
            i18nModalTitle={tasksKeys.invitedUsers}
            onRemoveUser={handleRemoveUser}
            users={users}
            usersFetched={usersFetched}
          />
        </div>
      )}
    </div>
  );
}

export default UsersAvatarsFieldControl;
