import { ClassName } from '../../../../../../../../types';

import { CreateProjectInTeamFormSingleTaskItemsTable } from '../../CreateProjectInTeamFormSingleTaskItemsTable';
import { CreateProjectInTeamFormItemTypeCardsGrid } from '../../CreateProjectInTeamFormItemTypeCardsGrid';

import { PureButtonHelper } from '../../../../../../../../helpers/buttons/PureButtonHelper';
import { Translate } from '../../../../../../../../helpers/Translate';

import { stringsKeys, words } from '../../../../../../../../locales/keys';

interface CreateProjectInTeamAuthFormSecondStepContentProps {
  className: ClassName;
  currencyExchangeRate: number;
  currencyPrefix: string;
  watchProjectTotal: number;
}

function CreateProjectInTeamAuthFormSecondStepContent({
  className,
  currencyExchangeRate,
  currencyPrefix,
  watchProjectTotal
}: CreateProjectInTeamAuthFormSecondStepContentProps) {
  return (
    <div className={className}>
      <div className="px-4">
        <h2 className="text-2xl font-extrabold text-gray-900 dark:text-gray-200 mb-6">
          <Translate id={words.scopeOfWork} />
        </h2>
        <div className="text-md text-gray-700 dark:text-gray-300 mb-6">
          <p>
            <Translate id={stringsKeys.hereIsABriefOfOurServicesAndPricesNo} />
          </p>
          <p>
            <Translate id={stringsKeys.unsureClickStartProjectAnd} />
          </p>
        </div>
      </div>

      <div className="p-4">
        <CreateProjectInTeamFormSingleTaskItemsTable
          currencyExchangeRate={currencyExchangeRate}
          currencyPrefix={currencyPrefix}
          watchProjectTotal={watchProjectTotal}
        />
      </div>

      {/*<div className="pl-4 pr-14 flex justify-end">*/}
      {/*  <PureButtonHelper*/}
      {/*    className="p-0 text-sm leading-5 focus:ring-base text-current hover:underline"*/}
      {/*    text="Clear scope of work"*/}
      {/*  />*/}
      {/*</div>*/}

      <CreateProjectInTeamFormItemTypeCardsGrid />
    </div>
  );
}

export default CreateProjectInTeamAuthFormSecondStepContent;
