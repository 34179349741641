import { useCallback, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { OnDragEndResponder } from '@hello-pangea/dnd';

import { useCreateProjectInTeamFormContext } from '../../../../hooks/useCreateProjectInTeamFormContext';
import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useTranslate } from '../../../../../../../../../common/hooks/useTranslate';

import { CreateProjectInTeamFormData } from '../../../../CreateProjectInTeamForm.types';
import { Currencies, ID } from '../../../../../../../../../types';
import { CreateProjectInTeamFormItemsTableHandle } from '../../../CreateProjectInTeamFormItemsTable/CreateProjectInTeamFormItemsTable';

import { tasksKeys } from '../../../../../../../../../locales/keys';

import { ProjectsPermissions } from '../../../../../../../projectsConstants';

function useCreateProjectInTeamFormTasksList() {
  const { t } = useTranslate();

  const { control } = useCreateProjectInTeamFormContext();

  const currentUser = useCurrentUser();

  const {
    append,
    fields: tasksFields,
    prepend,
    remove: removeTask
  } = useFieldArray<CreateProjectInTeamFormData, 'tasks'>({
    name: 'tasks',
    control
  });

  const tasksRef =
    useRef<Map<ID, CreateProjectInTeamFormItemsTableHandle>>(null);

  const getTasksRefsMap: () => Map<
    ID,
    CreateProjectInTeamFormItemsTableHandle
  > = () => {
    if (!tasksRef.current) {
      tasksRef.current = new Map();
    }

    return tasksRef.current;
  };

  const removeAllTasksItems = useCallback<() => void>(() => {
    const map = getTasksRefsMap();

    map.forEach((value) => value.removeItems());
  }, []);

  const handleDragItemEnd = useCallback<OnDragEndResponder>((result) => {
    const map = getTasksRefsMap();

    if (!result.source || !result.destination) {
      return;
    }

    const sourceTaskRef = map.get(result.source.droppableId);
    const destinationTaskRef = map.get(result.destination.droppableId);

    if (result.source.droppableId === result.destination.droppableId) {
      sourceTaskRef?.moveItem(result.source.index, result.destination.index);
    } else {
      const item = sourceTaskRef?.getItem(result.source.index);

      destinationTaskRef?.insertItem(result.destination.index, item);
      sourceTaskRef?.removeItem(result.source.index);
    }
  }, []);

  return {
    appendTask: useCallback<() => void>(
      () =>
        append({
          name: t(tasksKeys.new)
          /*items: currentUser.hasPermissions(
            ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_ITEMS
          )
            ? [{ itemTypeId: null, description: '', quantity: 1, price: 0 }]
            : undefined*/
        }),
      [append, t]
    ),
    getTasksRefsMap,
    handleDragItemEnd,
    prependTask: useCallback<() => void>(
      () =>
        prepend({
          name: t(tasksKeys.new),
          items: currentUser.hasPermissions(
            ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_ITEMS
          )
            ? [
                {
                  itemTypeId: null,
                  description: '',
                  quantity: 1,
                  price: 0,
                  viewPrice: 0,
                  viewPriceCurrency: Currencies.USD
                }
              ]
            : undefined
        }),
      [currentUser, prepend, t]
    ),
    removeAllTasksItems,
    removeTask,
    tasksFields
  };
}

export default useCreateProjectInTeamFormTasksList;
