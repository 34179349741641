import { Control, Controller, FieldPath } from 'react-hook-form';

import { I18nText, IsDisabled } from '../../../types';
import { UserID } from '../../../main/users/usersTypes';

import { UsersAvatarsFieldControl } from './components/UsersAvatarsFieldControl';

interface UsersAvatarsFieldProps<FormDataType> {
  control: Control<FormDataType>;
  disabled?: IsDisabled;
  i18nLabel?: I18nText;
  name: FieldPath<FormDataType>;
}
function UsersAvatarsField<FormDataType>({
  control,
  disabled,
  i18nLabel,
  name
}: UsersAvatarsFieldProps<FormDataType>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <UsersAvatarsFieldControl
          disabled={disabled}
          fieldName={name}
          i18nLabel={i18nLabel}
          onChange={onChange}
          value={value as UserID[]}
        />
      )}
    />
  );
}

export default UsersAvatarsField;
